import { useEffect, useRef, useState } from 'react'
import { useGlobalTimezone } from './useGlobalTimezone'

/** Usage:
 * ```ts
 * const clock = useGlobalTimezoneClock()
 * ```
 * where `clock = '12:34:56'` formatted in selected timezone
 * */
export const useGlobalTimezoneClock = () => {
  const { timeFormatter } = useGlobalTimezone()
  const [timeFormatted, setTimeFormatted] = useState<string>(timeFormatter.format(new Date()))
  const formatterRef = useRef(timeFormatter)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeFormatted(formatterRef.current.format(new Date()))
    }, 1000 - (Date.now() % 1000))

    return () => clearTimeout(timeout)
  }, [timeFormatted])

  useEffect(() => {
    setTimeFormatted(timeFormatter.format(new Date()))
    formatterRef.current = timeFormatter
  }, [timeFormatter])

  return timeFormatted
}
