import { AllowedTradeKeys, TradesTableHeader } from './models'

export const TRADES_TABLE_KEYS_DEFAULT: AllowedTradeKeys[] = [
  'timeEntry',
  'timeExit',
  'instrumentAlias',
  'maxSize',
  'priceEntry',
  'priceExit',
  'profit',
  'isBuy',
  'mfe',
  'mae',
  'fills',
]

// prettier-ignore
export const TRADES_TABLE_AVAILABLE_HEADERS_MAP: Record<AllowedTradeKeys, TradesTableHeader> = {
  timeEntry:        { sortableKey: 'timeEntry',       name: 'Entry date' },
  timeExit:         { sortableKey: 'timeExit',        name: 'Exit date' },
  instrumentAlias:  { sortableKey: 'instrumentAlias', name: 'Instrument' },
  maxSize:          { sortableKey: 'maxSize',         name: 'Max size' },
  priceEntry:       { sortableKey: 'priceEntry',      name: 'Entry price' },
  priceExit:        { sortableKey: 'priceExit',       name: 'Exit price' },
  profit:           { sortableKey: 'profit',          name: 'Profit' },
  isBuy:            { sortableKey: 'isBuy',           name: 'Type' },
  mfe:              { sortableKey: 'mfe',             name: 'MFE',          tooltip: 'Maximum Favorable Excursion' },
  mae:              { sortableKey: 'mae',             name: 'MAE',          tooltip: 'Maximum Adverse Excursion' },
  commission:       { sortableKey: 'commission',      name: 'Commission' },
  postMae:          { sortableKey: 'postMae',         name: 'Post MAE' },
  postMfe:          { sortableKey: 'postMfe',         name: 'Post MFE' },
  fills:            { sortableKey: 'fills',           name: 'Fills' },
}

export const TRADES_TABLE_ALLOWED_KEYS_ALL: AllowedTradeKeys[] = Object.keys(
  TRADES_TABLE_AVAILABLE_HEADERS_MAP
) as AllowedTradeKeys[]
