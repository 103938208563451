import './LabeledDivider.scss'

type LabeledDividerProps = Readonly<{ label: string }>

export function LabeledDivider({ label }: LabeledDividerProps) {
  return (
    <div className="LabeledDivider">
      <div className="LabeledDivider__line"></div>
      <div className="LabeledDivider__text">{label}</div>
      <div className="LabeledDivider__line"></div>
    </div>
  )
}
