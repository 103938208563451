import './PageLayout.scss'
import { AllHTMLAttributes } from 'react'
import classNames from 'classnames'
import { PageLayoutCustomHeader } from './components/PageLayoutCustomHeader'
import { PageLayoutCustomFooter } from './components/PageLayoutCustomFooter'
import { PageLayoutCustomContent } from './components/PageLayoutCustomContent'
import { PageLayoutHeader } from './components/PageLayoutHeader'
import { PageLayoutFooter } from './components/PageLayoutFooter'

type PageLayoutProps = { children: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function PageLayout({ children, className, ...props }: Readonly<PageLayoutProps>) {
  return (
    <div className={classNames('PageLayout', className ?? '')} {...props}>
      {children}
    </div>
  )
}

PageLayout.CustomHeader = PageLayoutCustomHeader
PageLayout.CustomFooter = PageLayoutCustomFooter

PageLayout.Header = PageLayoutHeader
PageLayout.Content = PageLayoutCustomContent
PageLayout.Footer = PageLayoutFooter
export default PageLayout
