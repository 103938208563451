import { useContext, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FilterContext, FilterSerializedState } from './FilterContext'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import { JournalInfo } from 'core/types'
import { LS_DASHBOARD_FILTERS, MS_IN_DAY } from 'core/constants'
import { useJournals } from 'core/api/journals'
import { useJournalsAll } from 'core/hooks/useJournalsAll'

const DATE_FROM_DEFAULT = new Date(new Date().setHours(12, 0, 0, 0) - MS_IN_DAY * 30)
const DATE_TO_DEFAULT = new Date(new Date().setHours(12, 0, 0, 0) + MS_IN_DAY)

export function useDashboardPageSearchParamsResolver() {
  const resolved = useInitialSearchParamsResolving()
  useResolvingFilterSearchParams({ skip: !resolved })
}

function useInitialSearchParamsResolving() {
  const { openAddDialog } = useJournalsApi()
  const journals = useJournals()
  const journalsAll = useJournalsAll()
  const { setFilters } = useContext(FilterContext)

  const alreadyResolved = useRef({
    journals: false,
    other: false,
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (alreadyResolved.current.other) return
    if (journals.isLoading) return
    if (!journals.data) return
    let searchParams = new URLSearchParams(window.location.search)

    searchParams = handleSearchParam(['modal'], searchParams, ([modalName]) => {
      if (!modalName) return
      if (modalName === 'add_journal') {
        openAddDialog()
      }
    })

    const savesFromLocalStorage: FilterSerializedState | null = JSON.parse(
      localStorage.getItem(LS_DASHBOARD_FILTERS) ?? 'null'
    )

    searchParams = handleSearchParam(
      ['symbols', 'date_from', 'date_to'],
      searchParams,
      ([symbols, dateFrom, dateTo]) => {
        const symbolsQueryParsed = symbols?.split(',')
        const symbolsLsParsed = savesFromLocalStorage?.symbols
        if (symbolsLsParsed) {
          setFilters((filters) => ({ ...filters, symbols: symbolsLsParsed }))
        } else if (symbolsQueryParsed) {
          setFilters((filters) => ({ ...filters, symbols: symbolsQueryParsed }))
        }

        if (dateFrom) {
          try {
            const dateFromObj = new Date(dateFrom)
            setFilters((filters) => ({ ...filters, dateFrom: dateFromObj }))
          } catch (e) {}
        } else if (savesFromLocalStorage?.dateFrom) {
          try {
            const dateFromObj = new Date(savesFromLocalStorage.dateFrom)
            setFilters((filters) => ({ ...filters, dateFrom: dateFromObj }))
          } catch (e) {}
        } else {
          setFilters((filters) => ({ ...filters, dateFrom: DATE_FROM_DEFAULT }))
        }

        if (dateTo) {
          try {
            const dateToObj = new Date(dateTo)
            setFilters((filters) => ({ ...filters, dateTo: dateToObj }))
          } catch (e) {}
        } else if (savesFromLocalStorage?.dateTo) {
          try {
            const dateToObj = new Date(savesFromLocalStorage.dateTo)
            setFilters((filters) => ({ ...filters, dateTo: dateToObj }))
          } catch (e) {}
        } else {
          setFilters((filters) => ({ ...filters, dateTo: DATE_TO_DEFAULT }))
        }
      }
    )

    navigate({ search: searchParams.toString() }, { replace: true })
    alreadyResolved.current.other = true
    // setResolved((resolved) => ({ ...resolved, other: true }))
  }, [journals, navigate, openAddDialog, setFilters])

  useEffect(() => {
    if (alreadyResolved.current.journals) return
    if (journalsAll.isLoading) return
    if (!journalsAll.data) return
    let searchParams = new URLSearchParams(window.location.search)

    searchParams = handleSearchParam(
      ['journal_id', 'namespace_code'],
      searchParams,
      ([journalId, namespaceCode]) => {
        if (!journalsAll.data) return
        const journal = getJournalToSet(journalsAll.data ?? [], journalId, namespaceCode)
        if (!journal) return
        return { journal_id: journal.id }
      }
    )

    const savesFromLocalStorage: FilterSerializedState | null = JSON.parse(
      localStorage.getItem(LS_DASHBOARD_FILTERS) ?? 'null'
    )
    searchParams = handleSearchParam(['journal_id'], searchParams, ([journalId]) => {
      const desiredJournalId = journalId ?? savesFromLocalStorage?.journalId
      const journal = journalsAll.data!.find((j) => j.id === desiredJournalId)
      journal && setFilters((filters) => ({ ...filters, journal: journal.id }))
    })

    navigate({ search: searchParams.toString() }, { replace: true })
    alreadyResolved.current.journals = true
    // setResolved((resolved) => ({ ...resolved, journals: true }))
    /* // eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [journalsAll, navigate, setFilters])

  return alreadyResolved.current.journals && alreadyResolved.current.other
}

function handleSearchParam(
  paramsKeys: string[],
  searchParams: URLSearchParams,
  handler: (paramKeyValues: (string | null)[]) => Record<string, string> | void
): URLSearchParams {
  const newSearchParams = new URLSearchParams(searchParams.toString())

  const paramKeyValues = paramsKeys.map((paramKey) => newSearchParams.get(paramKey))
  paramsKeys.forEach((paramKey) => newSearchParams.delete(paramKey))
  const outputParamsMap = handler(paramKeyValues)
  if (outputParamsMap) {
    Object.entries(outputParamsMap).forEach(([key, value]) => {
      if (value != null) newSearchParams.set(key, value)
    })
  }
  return newSearchParams
}

/** Read filters & update search params on each change */
function useResolvingFilterSearchParams({ skip = false }) {
  const [searchParams] = useSearchParams()
  const { filters } = useContext(FilterContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (skip) return
    const updateSearchParams = (search: string) => navigate({ search }, { replace: true })
    const newSearchParams = new URLSearchParams(searchParams.toString())

    filters.journal
      ? newSearchParams.set('journal_id', filters.journal)
      : newSearchParams.delete('journal_id')
    filters.symbols.length
      ? newSearchParams.set('symbols', filters.symbols.join(','))
      : newSearchParams.delete('symbols')
    newSearchParams.set('date_from', filters.dateFrom.toISOString())
    newSearchParams.set('date_to', filters.dateTo.toISOString())
    updateSearchParams(newSearchParams.toString())

    return () => {
      const newSearchParams = new URLSearchParams(searchParams.toString())
      newSearchParams.delete('journal_id')
      newSearchParams.delete('symbols')
      newSearchParams.delete('date_from')
      newSearchParams.delete('date_to')
      updateSearchParams(newSearchParams.toString())
    }
  }, [filters, searchParams, skip, navigate])
}

/** Calculates journal to be set in filters based on search params. If returns undefined - search params are invalid */
function getJournalToSet(
  journals: JournalInfo[],
  journalId: string | null,
  namespaceCode: string | null
): JournalInfo | undefined {
  if (journalId === null && namespaceCode === null) return

  const journalsWithId = journalId ? journals.filter((j) => j.id === journalId) : []
  const journalsWithNamespace = namespaceCode
    ? journals.filter((j) => j.namespaceCode === namespaceCode)
    : []

  if (onlyJournalIdOk() === false) return
  if (onlyJournalTypeOk() === false) return
  if (bothIdAndTypeOk() === false) return

  if (journalsWithId[0]) return journalsWithId[0]
  if (journalsWithNamespace[0]) return journalsWithNamespace[0]
  return void 0

  // Helper functions.
  // false - means that the condition is not met, interrupt;
  // undefined - means that conditions does not make sense here - skip

  function onlyJournalIdOk() {
    if (!(journalId !== null && namespaceCode === null)) return
    if (journalsWithId === null || journalsWithId.length !== 1) return false
    return true
  }

  function onlyJournalTypeOk() {
    if (!(journalId === null && namespaceCode !== null)) return
    if (journalsWithNamespace.length !== 1) return false
    return true
  }

  function bothIdAndTypeOk() {
    if (!(journalId !== null && namespaceCode !== null)) return
    if (journalsWithId.length !== 1 || journalsWithId[0].namespaceCode !== namespaceCode)
      return false
    return true
  }
}
