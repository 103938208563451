import { useNavigate } from 'react-router-dom'

import PageLayout from 'shared/PageLayout'
import Card from 'shared/Card'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'

import './ManageJournalsPage.scss'
import { SimpleDropdown } from 'shared/Dropdown'
import { useGlobalTimezoneClock, useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { Button } from 'shared/Button'
import { MyJournalsTable } from './tables/MyJournalsTable'
import { SharedJournalsTable } from './tables/SharedJournalsTable'
import Tabs from 'shared/Tabs'
import { useJournalsDetailed, useJournalsSharedWithMeDetailed } from 'core/api/journals'

export function ManageJournalsPage() {
  const navigate = useNavigate()
  const { openAddDialog } = useJournalsApi()

  const { availableTimeZonesNameOptions, setTimeZone, timeZone } = useGlobalTimezone()
  const timeFormatted = useGlobalTimezoneClock()
  const journalsDetailed = useJournalsDetailed()
  const journalsDetailedShared = useJournalsSharedWithMeDetailed()

  return (
    <PageLayout className="ManageJournalsPage">
      <PageLayout.Header />
      <PageLayout.Content>
        <div className="heading">
          <h4>Journal manager</h4>

          <Button appearance="outline" onClick={() => navigate('/dashboard')}>
            &lt; Back to main page
          </Button>
        </div>

        <Card className="ManageJournalsPage__content">
          <div className="ManageJournalsPage__content__heading">
            <div className="buttons">
              <SimpleDropdown
                value={timeZone}
                onChange={setTimeZone}
                getVisualValue={(v) => `${timeFormatted} (UTC${v.timezoneOffsetName})`}
              >
                {availableTimeZonesNameOptions.map((tz) => (
                  <SimpleDropdown.Item value={tz} key={tz.timezoneOffsetName}>
                    <SimpleDropdown.ItemText
                      primary={
                        'UTC' +
                        tz.timezoneOffsetName +
                        (tz.abbreviations ? ` (${tz.abbreviations.join(', ')})` : '')
                      }
                      secondary={Array.from(tz.regions).join(', ')}
                    />
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>

              <Button onClick={() => openAddDialog()} size="small" className="add-button">
                + Add journal
              </Button>
            </div>
          </div>
          <Tabs defaultTabId="my-journals">
            <Tabs.Buttons>
              <Tabs.Button tabId="my-journals">
                My journals
                {journalsDetailed.data !== undefined && ` (${journalsDetailed.data.length})`}
              </Tabs.Button>
              <Tabs.Button tabId="shared-journals">
                Shared with me
                {journalsDetailedShared.data !== undefined &&
                  ` (${journalsDetailedShared.data.length})`}
              </Tabs.Button>
            </Tabs.Buttons>
            <Tabs.Content tabId="my-journals">
              <MyJournalsTable />
            </Tabs.Content>
            <Tabs.Content tabId="shared-journals">
              <SharedJournalsTable />
            </Tabs.Content>
          </Tabs>
        </Card>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
