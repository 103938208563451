import { API_URL, LS_TRADES_TABLE_COLUMNS } from 'core/constants'
import { useEffect, useMemo, useState } from 'react'
import { useNotifications } from 'shared/Notifications'
import { appFetch, HttpError } from 'core/utils'
import { JournalInfo, TradeItem } from 'core/types'
import { getInitialTradesTableKeys } from './utils'
import { TRADES_TABLE_AVAILABLE_HEADERS_MAP } from './constants'
import { AllowedTradeKeys, TradesTableHeader } from './models'

type UseTradesTableHeadersHookResponse = {
  columns: TradesTableHeader[]
  setColumnsKeys: React.Dispatch<React.SetStateAction<AllowedTradeKeys[]>>
  isColumSelectedMap: Record<AllowedTradeKeys, boolean>
}
export function useTradesTableHeaders(): UseTradesTableHeadersHookResponse {
  const [columnsKeys, setColumnsKeys] = useState<AllowedTradeKeys[]>(() =>
    getInitialTradesTableKeys()
  )
  const columns: TradesTableHeader[] = useMemo(
    () => columnsKeys.map((key) => TRADES_TABLE_AVAILABLE_HEADERS_MAP[key]),
    [columnsKeys]
  )
  const isColumSelectedMap: Record<AllowedTradeKeys, boolean> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(TRADES_TABLE_AVAILABLE_HEADERS_MAP).map(([key, _]) => [
          key,
          columnsKeys.includes(key as AllowedTradeKeys),
        ])
      ) as Record<AllowedTradeKeys, boolean>,
    [columnsKeys]
  )

  useEffect(() => {
    localStorage.setItem(LS_TRADES_TABLE_COLUMNS, JSON.stringify(columnsKeys))
  }, [columnsKeys])

  return { columns, setColumnsKeys, isColumSelectedMap }
}

export function useTradesTableApi() {
  const [isLoading, setIsLoading] = useState(false)
  const { showNotification } = useNotifications()

  const deleteTrade = async (tradeItem: TradeItem) => {
    setIsLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/trades/${tradeItem.id}`, 'DELETE')
      showNotification({ text: 'Trade deleted successfully', type: 'success' })
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 406) {
          return showNotification({
            text: "Journal type doesn't support trades deletion",
            type: 'error',
          })
        } else if (err.status === 404) {
          return showNotification({ text: 'Trade does not exist', type: 'error' })
        } else {
          return showNotification({ text: 'Failed to delete trade', type: 'error' })
        }
      } else {
        console.error(err)
        showNotification({ text: 'Unknown error', type: 'error' })
      }
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  const moveTrade = async (tradeItem: TradeItem, journal: JournalInfo) => {
    setIsLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/trades/moveTo/${journal.id}`, 'PUT', [tradeItem.id])
      showNotification({
        text: 'Trade moved successfully to ' + journal.journalName,
        type: 'success',
      })
    } catch (err) {
      showNotification({ text: 'Failed to move trade', type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, deleteTrade, moveTrade }
}
