import { InfoTooltip } from 'shared/InfoTooltip/InfoTooltip'
import './SimpleStatisticsInfo.scss'

type Props = {
  imgUrl: string
  title: string
  value: string | number
  color: 'red' | 'green'
  tooltipText?: string
}
export function SimpleStatisticsInfo({
  imgUrl,
  title,
  value,
  color,
  tooltipText,
}: Readonly<Props>) {
  return (
    <div className="SimpleStatisticsInfo">
      <div className="SimpleStatisticsInfo__icon">
        <div className={'SimpleStatisticsInfo__icon__bg ' + color}>
          <img src={imgUrl} alt="icon" width="24px" height="24px" />
        </div>
      </div>
      <div className="SimpleStatisticsInfo__title">
        <div>{title}</div>
        {tooltipText && <InfoTooltip>{tooltipText}</InfoTooltip>}
      </div>
      <div className="SimpleStatisticsInfo__value">{value}</div>
    </div>
  )
}
