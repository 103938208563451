import { IconsSrc } from 'core/assets'
import './ModalLayout.scss'
import { AllHTMLAttributes } from 'react'
import { Button, ButtonProps } from 'shared/Button'

type ModalLayoutProps = { children?: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalLayout({ children, className, ...props }: Readonly<ModalLayoutProps>) {
  return (
    <div className={'ModalLayout ' + className} {...props}>
      {children}
    </div>
  )
}

type ModalLayoutCloseButtonProps = ButtonProps
function ModalLayoutCloseButton(props: Readonly<ModalLayoutCloseButtonProps>) {
  return (
    <Button appearance="text" square className="ModalLayoutCloseButton" {...props}>
      <img src={IconsSrc.Cross} alt="Close" />
    </Button>
  )
}

type ModalLayoutHeadingProps = {
  children?: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutHeading({ children, ...props }: Readonly<ModalLayoutHeadingProps>) {
  return (
    <div className="ModalLayoutHeading" {...props}>
      {children}
    </div>
  )
}

type ModalLayoutDescriptionProps = { children: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutDescription({ children, ...props }: Readonly<ModalLayoutDescriptionProps>) {
  return (
    <div className="ModalLayoutDescription" {...props}>
      {children}
    </div>
  )
}

type ModalLayoutButtonsProps = { children?: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutButtons({ children, ...props }: Readonly<ModalLayoutButtonsProps>) {
  return (
    <div className="ModalLayoutButtons" {...props}>
      {children}
    </div>
  )
}

type ModalLayoutFormControlProps = AllHTMLAttributes<HTMLDivElement> & {
  labelElement?: React.ReactNode
  children?: React.ReactNode
}
function ModalLayoutFormControl({
  children,
  label,
  labelElement,
  className,
  ...props
}: Readonly<ModalLayoutFormControlProps>) {
  return (
    <div className={'ModalLayoutFormControl ' + className ?? ''} {...props}>
      {label && <div className="ModalLayoutFormControl__label">{label}</div>}
      {labelElement && <div className="ModalLayoutFormControl__label">{labelElement}</div>}
      {children}
    </div>
  )
}

type ModalLayoutFormControlErrorProps = {
  children: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutFormControlError({
  children,
  ...props
}: Readonly<ModalLayoutFormControlErrorProps>) {
  return (
    <div className="ModalLayoutFormControlError" {...props}>
      {children}
    </div>
  )
}
ModalLayoutFormControl.Error = ModalLayoutFormControlError

ModalLayout.CloseButton = ModalLayoutCloseButton
ModalLayout.Heading = ModalLayoutHeading
ModalLayout.Description = ModalLayoutDescription
ModalLayout.Buttons = ModalLayoutButtons
ModalLayout.FormControl = ModalLayoutFormControl
export default ModalLayout
