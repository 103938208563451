import './TableConfigModal.scss'
import { Button } from 'shared/Button'
import { Modal, ModalController } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import {
  TRADES_TABLE_ALLOWED_KEYS_ALL,
  TRADES_TABLE_AVAILABLE_HEADERS_MAP,
} from '../../TradesTableSectionContext/constants'
import { useForm } from 'react-hook-form'
import { AllowedTradeKeys } from '../../TradesTableSectionContext/models'
import { useContext, useState } from 'react'
import { TradesTableSectionContext } from '../../TradesTableSectionContext'

function getInitialFormResult(selectedKeys: AllowedTradeKeys[]): Record<AllowedTradeKeys, boolean> {
  const entries = TRADES_TABLE_ALLOWED_KEYS_ALL.map(
    (k) => [k, selectedKeys.includes(k)] as [AllowedTradeKeys, boolean]
  )
  return Object.fromEntries(entries) as Record<AllowedTradeKeys, boolean>
}

type TableConfigModalProps = { controller: ModalController }
export function TableConfigModal({ controller }: Readonly<TableConfigModalProps>) {
  const { setColumnsKeys, columns } = useContext(TradesTableSectionContext)
  const [defaultValues] = useState(() =>
    getInitialFormResult(columns.map((c) => c.sortableKey) as AllowedTradeKeys[])
  )
  const { handleSubmit, register } = useForm<Record<AllowedTradeKeys, boolean>>({ defaultValues })

  const onSubmit = handleSubmit((data) => {
    const entries = Object.entries(data) as [AllowedTradeKeys, boolean][]
    const result = entries
      .map(([key, value]) => {
        if (value === true) return key
        return null
      })
      .filter((key) => key !== null) as AllowedTradeKeys[]
    setColumnsKeys(result)
  })

  const onSave = () => {
    onSubmit()
    controller.close()
  }

  return (
    <Modal controller={controller}>
      <ModalLayout className="TableConfigModal">
        <ModalLayout.Heading>Table configuration</ModalLayout.Heading>
        <form onSubmit={onSubmit}>
          {Object.values(TRADES_TABLE_AVAILABLE_HEADERS_MAP).map((header) => {
            return (
              <div className="form-control" key={header.sortableKey}>
                <input
                  type="checkbox"
                  id={header.sortableKey}
                  {...register(header.sortableKey as AllowedTradeKeys)}
                />
                <label htmlFor={header.sortableKey}>{header.name}</label>
              </div>
            )
          })}
        </form>
        <ModalLayout.Buttons>
          <Button onClick={() => controller.close()}>Cancel</Button>
          <Button color="primary" onClick={() => onSave()}>
            Save
          </Button>
        </ModalLayout.Buttons>
      </ModalLayout>
    </Modal>
  )
}
