import { useJournalsSharedWithMeDetailed } from 'core/api/journals'
import { JournalDetailedInfo } from 'core/types'
import { useEffect } from 'react'
import Table from 'shared/Table'
import { useJournalSorting } from '../useJournalSorting'
import { JOURNALS_TYPE_NAME_MAP } from 'core/journalsNameMap'
import TextDate from 'shared/TextDate'
import { useNavigate } from 'react-router-dom'
import TextMoney from 'shared/TextMoney'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import Menu from 'shared/Menu'
import { Button } from 'shared/Button'
import { IconsSrc } from 'core/assets'

export function SharedJournalsTable() {
  const journalsDetailed = useJournalsSharedWithMeDetailed()
  const { openRevokeAccessForMeModal } = useJournalsApi()
  const { sortedItems, sortingKey, setItems, direction, sortItems } = useJournalSorting()
  const navigate = useNavigate()

  useEffect(() => {
    if (!journalsDetailed.data) return setItems([])
    setItems(journalsDetailed.data)
  }, [journalsDetailed.data, setItems])

  return (
    <Table tableNeighbor={journalsDetailed.data?.length === 0 && <EmptyState />}>
      <Table.Header>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="journalName"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="journalType"
          currentSortableKey={sortingKey}
          sortableDirection={direction}
          onSort={(key) => sortItems(key)}
        >
          Type
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="creationDate"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Creation date
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="totalTrades"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Trades
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="totalPnl"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          P&L
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="maxDrawdown"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Max drawdown
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="owner"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Owner
        </Table.HeaderCell>
        <Table.HeaderCell.Empty />
      </Table.Header>
      <Table.Body>
        {sortedItems.map((journal) => (
          <Table.Row
            key={journal.id}
            onClick={() => navigate('/dashboard?journal_id=' + journal.id)}
          >
            <Table.Cell>{journal.journalName}</Table.Cell>
            <Table.Cell>
              {JOURNALS_TYPE_NAME_MAP[journal.journalType] ?? journal.journalType}
            </Table.Cell>
            <Table.Cell>
              {journal.creationDate ? <TextDate date={new Date(journal.creationDate)} /> : '-'}
            </Table.Cell>
            <Table.Cell>{journal.totalTrades}</Table.Cell>
            <Table.Cell>
              <TextMoney value={journal.totalPnl} colored />
            </Table.Cell>
            <Table.Cell>
              <TextMoney value={journal.maxDrawdown} colored />
            </Table.Cell>
            <Table.Cell>{journal.owner.userEmail}</Table.Cell>

            <Table.Cell.Menu>
              <Menu
                trigger={
                  <Button appearance="text" square={true} size="small">
                    <img src={IconsSrc.DotsVertical} alt="Menu" />
                  </Button>
                }
              >
                <Menu.Item onClick={() => openRevokeAccessForMeModal(journal.id)}>
                  <img src={IconsSrc.Trash} alt="Delete" width={20} height={20} /> Remove
                </Menu.Item>
              </Menu>
            </Table.Cell.Menu>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

function EmptyState() {
  return (
    <div className="EmptyState">
      <div className="primary">No shared journals</div>
      <div className="secondary">
        You don’t have any journals shared with you yet. Once someone shares a journal, it will
        appear here.
      </div>
    </div>
  )
}
