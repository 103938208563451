import classNames from 'classnames'
import calendarIco from 'assets/icons/calendar-event.svg'
import { Button } from 'shared/Button'
import { useCompactTimeFormatter } from '../hooks'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'

type DateInputTriggerProps = {
  open: boolean
  value: Date
}
export function DateInputTrigger({ open, value }: Readonly<DateInputTriggerProps>) {
  const compactTimeFormatter = useCompactTimeFormatter()
  const { dateFormatter } = useGlobalTimezone()

  return (
    <Button
      appearance="input-trigger"
      className={classNames({
        DateInput__trigger: true,
        DateInput__trigger__activated: open,
      })}
    >
      <img className="DateInput__trigger__icon" src={calendarIco} alt="Calendar" />
      <span className="DateInput__trigger__value text-normal-regular">
        <span>{dateFormatter.format(value)}</span>
        <div className="divider">|</div>
        <span>{compactTimeFormatter.format(value)}</span>
      </span>
    </Button>
  )
}
