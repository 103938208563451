import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { NotificationItem, NotificationItemCreateData } from './types'

type NotificationsContextData = {
  notifications: NotificationItem[]
  showNotification: (notification: NotificationItemCreateData) => void
  removeNotification: (id: NotificationItem['id']) => void
}

export const NotificationsContext = createContext<NotificationsContextData>({
  notifications: [],
  showNotification: () => {},
  removeNotification: () => {},
})

export const useNotifications = () => useContext(NotificationsContext)

export function NotificationContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [notifications, setNotifications] = useState<NotificationItem[]>([])

  const removeNotification = useCallback(
    (id: NotificationItem['id']) => {
      setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id))
    },
    [setNotifications]
  )

  const showNotification = useCallback(
    (notification: NotificationItemCreateData) => {
      const id = Math.random().toString()
      const patchedNotification: NotificationItem = {
        ...notification,
        id,
        removeTimeout: notification.removeTimeout ?? 5000,
        actionName: notification.actionName ?? 'OK',
      }

      setNotifications([...notifications, patchedNotification])
      setTimeout(() => removeNotification(id), patchedNotification.removeTimeout)
    },
    [notifications, setNotifications, removeNotification]
  )

  const providerValue = useMemo(
    () =>
      ({
        notifications,
        showNotification,
        removeNotification,
      } satisfies NotificationsContextData),
    [notifications, showNotification, removeNotification]
  )

  return (
    <NotificationsContext.Provider value={providerValue}>{children}</NotificationsContext.Provider>
  )
}
