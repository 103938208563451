import { calcTimezoneOffsetMap } from './utils'

export const TIMEZONE_LS_TOKEN = 'TJ_TIMEZONE'
export const INITIAL_FORMATTING_OPTIONS = {
  ...new Intl.DateTimeFormat().resolvedOptions(),
  locale: 'en-GB',
}
export const DATE_FORMATTER_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
}
export const TIME_FORMATTER_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: false,
  month: 'short',
  day: 'numeric',
  year: 'numeric',
}
export const ZONES_ABBREVIATION_MAP: Record<string, string[]> = {
  '-12:00': [],
  '-11:00': ['SST'],
  '-10:00': ['HST'],
  '-09:00': ['HDT', 'AKST'],
  '-08:00': ['HDT', 'PST', 'AKDT'],
  '-07:00': ['PDT', 'MST'],
  '-06:00': ['MDT', 'CST'],
  '-05:00': ['CDT', 'EST'],
  '-04:00': ['AST', 'EDT'],
  '-03:00': ['ADT'],
  '-02:00': [],
  '-01:00': [],
  '+00:00': ['UTC', 'GMT'],
  '+01:00': ['CET'],
  '+02:00': ['EET'],
  '+03:00': ['EEST'],
  '+05:30': ['IST'],
  '+07:00': ['WIB'],
  '+08:00': ['CST', 'WITA', 'PST', 'AWST'],
  '+09:00': [],
  '+10:00': [],
  '+11:00': [],
  '+12:00': [],
}

export const TIMEZONES_NAMES: string[] = (Intl as any).supportedValuesOf('timeZone') ?? []

export const [TIMEZONE_OFFSET_MAP, TIMEZONE_DEFAULT_ITEM] = calcTimezoneOffsetMap(
  TIMEZONES_NAMES,
  INITIAL_FORMATTING_OPTIONS,
  ZONES_ABBREVIATION_MAP
)
export const TIMEZONE_OFFSET_LIST = Object.values(TIMEZONE_OFFSET_MAP).sort(
  (a, b) => a.offsetMs - b.offsetMs
)
