import { useJournals, useJournalsSharedWithMe } from 'core/api/journals'
import { useMemo } from 'react'

export function useJournalsAll() {
  const journals = useJournals()
  const journalsShared = useJournalsSharedWithMe()
  const journalsAll = useMemo(() => {
    return [...(journals.data ?? []), ...(journalsShared.data ?? [])]
  }, [journals.data, journalsShared.data])

  const result = useMemo(() => {
    return {
      data: journals.data && journalsShared.data ? journalsAll : undefined,
      isLoading: journals.isLoading || journalsShared.isLoading,
    }
  }, [
    journals.data,
    journals.isLoading,
    journalsShared.data,
    journalsShared.isLoading,
    journalsAll,
  ])

  return result
}
