import './PasswordInput.scss'
import { forwardRef, HTMLProps, useMemo, useState } from 'react'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'

type PasswordInputProps = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
  isPasswordShown?: boolean
  onPasswordShownChange?: (value: boolean) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    { isPasswordShown: isPasswordShownExternal, onPasswordShownChange, onChange, ...props },
    ref
  ) => {
    const [isPasswordShownInternal, setIsPasswordShownInternal] = useState<boolean>(false)
    const isPasswordShown = isPasswordShownExternal ?? isPasswordShownInternal
    const mode = useMemo(() => (isPasswordShown ? 'text' : 'password'), [isPasswordShown])

    const toggleMode = () => {
      onPasswordShownChange && onPasswordShownChange(!isPasswordShown)
      setIsPasswordShownInternal((v) => !v)
    }

    return (
      <div className="PasswordInput">
        <input {...props} ref={ref} onChange={onChange} type={mode} />
        <Button onClick={() => toggleMode()} type="button" square appearance="text" size="small">
          <Icon name={isPasswordShown ? 'Eye' : 'EyeOff'} width={16} height={16} />
        </Button>
      </div>
    )
  }
)

export { PasswordInput }
