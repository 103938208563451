import classNames from 'classnames'
import { ButtonAlignment, ButtonAppearance, ButtonColor, ButtonSize } from './models'
import { useMemo } from 'react'

type ClassNameGenParams = {
  appearance?: ButtonAppearance
  color?: ButtonColor
  size?: ButtonSize
  square?: boolean
  alignment?: ButtonAlignment
  className?: string
  fontType?: 'button' | 'text'
  disablePadding?: boolean
}

const PARAMS_DEFAULTS: Required<ClassNameGenParams> = {
  appearance: 'solid',
  color: 'secondary',
  size: 'medium',
  square: false,
  alignment: 'center',
  className: '',
  fontType: 'button',
  disablePadding: false,
}

function classNamesCalc({
  appearance,
  color,
  size,
  className,
  square,
  alignment,
  fontType,
  disablePadding,
}: ClassNameGenParams = {}) {
  return classNames(
    'Button',
    `type-${appearance ?? PARAMS_DEFAULTS.appearance}`,
    `color-${color ?? PARAMS_DEFAULTS.color}`,
    `size-${size ?? PARAMS_DEFAULTS.size}`,
    `align-${alignment ?? PARAMS_DEFAULTS.alignment}`,
    `font-type-${fontType ?? PARAMS_DEFAULTS.fontType}`,
    square && 'square',
    disablePadding && 'disable-padding',
    className ?? ''
  )
}

function useButtonNameCalculated({
  appearance,
  color,
  size,
  className,
  alignment,
  square,
  fontType,
  disablePadding,
}: ClassNameGenParams = {}) {
  return useMemo(
    () =>
      classNamesCalc({
        appearance,
        color,
        size,
        className,
        square,
        alignment,
        fontType,
        disablePadding,
      }),
    [appearance, color, size, className, square, alignment, fontType, disablePadding]
  )
}

export default useButtonNameCalculated
