import ArrowLeft from 'assets/icons/arrow-left.svg'
import CalendarEvent from 'assets/icons/calendar-event.svg'
import ChevronsDown from 'assets/icons/chevrons-down.svg'
import CornerArrowDown from 'assets/icons/corner_arrow_down.svg'
import Cross from 'assets/icons/cross.svg'
import Edit from 'assets/icons/edit.svg'
import FinalUp from 'assets/icons/final_up.svg'
import Plus from 'assets/icons/plus.svg'
import Trash from 'assets/icons/trash.svg'
import ArrowRight from 'assets/icons/arrow-right.svg'
import CaretDown from 'assets/icons/caret-down.svg'
import ChevronsUp from 'assets/icons/chevrons-up.svg'
import CornerArrowUp from 'assets/icons/corner_arrow_up.svg'
import DotsVertical from 'assets/icons/dots-vertical.svg'
import FinalDown from 'assets/icons/final_down.svg'
// import Notification from 'assets/icons/notification.svg'
import Settings from 'assets/icons/settings.svg'
import Share from 'assets/icons/share.svg'
import Link from 'assets/icons/link.svg'
import Users from 'assets/icons/users.svg'
import Eye from 'assets/icons/eye.svg'
import EyeOff from 'assets/icons/eye-off.svg'

export const IconsSrc = {
  ArrowLeft,
  CalendarEvent,
  ChevronsDown,
  CornerArrowDown,
  Cross,
  Edit,
  FinalUp,
  Plus,
  Trash,
  ArrowRight,
  CaretDown,
  ChevronsUp,
  CornerArrowUp,
  DotsVertical,
  FinalDown,
  // Notification,
  Settings,
  Share,
  Link,
  Users,
  Eye,
  EyeOff,
}
