import { EMAIL_REGEXP, LICENSE_KEY_REGEXP } from 'core/regexp'
import { HttpError, appFetch } from 'core/utils'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { InfoTooltip } from 'shared/InfoTooltip/InfoTooltip'
import { API_URL, LS_ACCESS_TOKEN } from 'core/constants'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import PasswordInput from 'shared/PasswordInput'

type FormInputs = {
  email: string
  licenseKey: string
  password: string
  passwordConfirm: string
}

export function SignUpForm() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [userExist, setUserExist] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm<FormInputs>()

  const allFields = useWatch({ control })

  const sendRegisterRequest = async (data: FormInputs) => {
    setLoading(true)
    setUserExist(null)

    try {
      const resp = await appFetch(API_URL + '/api/dashboard/register', 'POST', {
        email: data.email,
        password: data.password,
        licenseKey: data.licenseKey,
      })

      if ('access_token' in resp) {
        localStorage.setItem(LS_ACCESS_TOKEN, resp.access_token)
        setTimeout(() => navigate('/'))
      } else {
        console.error('No access token in response!')
        setTimeout(() => navigate('/login'))
      }
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 409) {
          setUserExist(allFields.email ?? 'Unknown email')
          setTimeout(() => {
            trigger()
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = handleSubmit((data: FormInputs) => sendRegisterRequest(data))

  const paste = async () => {
    const value = await navigator.clipboard.readText()
    setValue('licenseKey', value.trim())
    trigger('licenseKey')
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <ModalLayout.FormControl label="Email">
        <input
          {...register('email', {
            required: 'Name is required',
            pattern: { value: EMAIL_REGEXP, message: 'Invalid email' },
            validate: (v) => v !== userExist || 'User already exists',
          })}
          type="text"
          id="email"
          placeholder="Enter email"
          autoComplete="false"
          className={classNames({ error: errors.email })}
        />

        {errors.email && (
          <ModalLayout.FormControl.Error>{errors.email.message}</ModalLayout.FormControl.Error>
        )}
      </ModalLayout.FormControl>
      <ModalLayout.FormControl
        labelElement={
          <div className="label-element">
            License key{' '}
            <InfoTooltip>
              The verification email from Bookmap Support after registration contains your license
              key. Also, you can find it in the user account on the Bookmap website or in the
              Bookmap desktop app (Help -&gt; About)
            </InfoTooltip>{' '}
          </div>
        }
      >
        <div className="input license-input">
          <input
            id="licenseKey"
            type="text"
            placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX"
            {...register('licenseKey', {
              required: 'License key is required',
              minLength: { value: 34, message: 'License key must be 34 characters' },
              pattern: {
                value: LICENSE_KEY_REGEXP,
                message: 'Invalid license key',
              },
            })}
            className={classNames({ error: errors.licenseKey })}
          />
          <Button type="button" onClick={() => paste()}>
            Paste
          </Button>
        </div>
        {errors.licenseKey && (
          <ModalLayout.FormControl.Error>{errors.licenseKey.message}</ModalLayout.FormControl.Error>
        )}
      </ModalLayout.FormControl>
      <ModalLayout.FormControl label="Password">
        <PasswordInput
          {...register('password', {
            required: 'Password is required',
            minLength: { value: 6, message: 'Password must be at least 6 characters' },
          })}
          id="password"
          type="password"
          autoComplete="new-password"
          placeholder="Enter password"
          className={classNames({ error: errors.password })}
        />
        {errors.password && (
          <ModalLayout.FormControl.Error>{errors.password.message}</ModalLayout.FormControl.Error>
        )}
      </ModalLayout.FormControl>
      <ModalLayout.FormControl label="Confirm password">
        <PasswordInput
          {...register('passwordConfirm', {
            required: 'Password confirmation is required',
            validate: (v) => v === allFields.password || 'Passwords do not match',
          })}
          id="passwordConfirm"
          type="password"
          autoComplete="new-password"
          placeholder="Confirm password"
          className={classNames({ error: errors.passwordConfirm })}
        />
        {errors.passwordConfirm && (
          <ModalLayout.FormControl.Error>
            {errors.passwordConfirm.message}
          </ModalLayout.FormControl.Error>
        )}
      </ModalLayout.FormControl>

      <Button
        id="register-button"
        color="primary"
        isLoading={loading}
        disabled={isSubmitted && !isValid}
      >
        Register
      </Button>
    </form>
  )
}
