import { Button } from 'shared/Button'

export type DropdownActionItemProps = {
  children: React.ReactNode
  onClick?: () => void
  onCloseMenu?: () => void
}
export function DropdownActionItem({
  children,
  onClick,
  onCloseMenu,
}: Readonly<DropdownActionItemProps>) {
  return (
    <Button
      appearance="menu-item"
      className="DropdownActionItem text-normal-regular"
      onClick={() => {
        onClick && onClick()
        onCloseMenu && onCloseMenu()
      }}
    >
      {children}
    </Button>
  )
}
