import classNames from 'classnames'
import './BarInfoSection.scss'
import { InfoTooltip } from 'shared/InfoTooltip/InfoTooltip'
import { AllHTMLAttributes } from 'react'

type BarInfoSectionProps = {
  label: string
  tooltipText?: string
  labelPositive: string
  labelNegative: string
  valuePositive: number | string | React.ReactNode
  valueNegative: number | string | React.ReactNode
  ratio: number | 'NaN'
  children: React.ReactNode | string
} & AllHTMLAttributes<HTMLDivElement>
/** Block with profit ratio */
export function BarInfoSection({
  label,
  tooltipText,
  labelPositive,
  labelNegative,
  valuePositive,
  valueNegative,
  ratio,
  children,
  ...props
}: Readonly<BarInfoSectionProps>) {
  return (
    <div {...props} className={'BarInfoSection ' + props.className}>
      <div className="info-grid">
        <div className="big">
          <BarInfoSectionLabel>
            {label} {tooltipText && <InfoTooltip>{tooltipText}</InfoTooltip>}
          </BarInfoSectionLabel>
          <div className="value">{children}</div>
        </div>
        <div>
          <BarInfoSectionLabel square="green">{labelPositive}</BarInfoSectionLabel>
          <div className="value">{valuePositive}</div>
        </div>
        <div>
          <BarInfoSectionLabel square="red">{labelNegative}</BarInfoSectionLabel>
          <div className="value">{valueNegative}</div>
        </div>
      </div>

      <section className="BarInfoSection__indicators">
        <Bar ratio={ratio} />
      </section>
    </div>
  )
}

type BarInfoSectionLabelProps = {
  children: React.ReactNode
  square?: 'red' | 'green'
  className?: string
}
function BarInfoSectionLabel({ children, square, className }: Readonly<BarInfoSectionLabelProps>) {
  return (
    <div className={classNames(['BarInfoSectionLabel', className || ''])}>
      {square && <div className={'BarInfoSectionLabel__square ' + square}></div>}
      <div className="BarInfoSectionLabel__label">{children}</div>
    </div>
  )
}

type BarProps = { ratio: number | 'NaN' }
function Bar({ ratio }: Readonly<BarProps>) {
  const numberRatio = isNaN(+ratio) ? 0 : +ratio

  if (isNaN(+ratio)) return <div className="Bar"></div>

  const greenStyles: React.CSSProperties = { width: numberRatio + '%' }
  const redStyles: React.CSSProperties = { width: 100 - numberRatio + '%' }
  return (
    <div className="Bar">
      <div className="Bar__indicator Bar__indicator--green" style={greenStyles}></div>
      <div className="Bar__indicator Bar__indicator--red" style={redStyles}></div>
    </div>
  )
}
