import { NotificationContextProvider } from './NotificationsContext'
import { NotificationsList } from './NotificationsList'

export function Notifications({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <NotificationContextProvider>
      <NotificationsList />
      {children}
    </NotificationContextProvider>
  )
}
