import './SignUp.scss'
import logo from 'assets/trading-journal-logo.svg'
import { LabeledDivider } from 'shared/LabeledDivider/LabeledDivider'
import { useNavigate } from 'react-router-dom'
import { SignUpForm } from './SignUpForm'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'

export function SignUp() {
  const navigate = useNavigate()

  return (
    <div className="SignUp card-page">
      <ModalLayout>
        <img src={logo} alt="Logo" />

        <SignUpForm />

        <div className="alternative-section">
          <LabeledDivider label="Have an account?" />
          <Button onClick={() => navigate('/login')}>Login</Button>
        </div>
      </ModalLayout>
    </div>
  )
}
