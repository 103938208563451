import {
  deleteJournal,
  editJournal,
  shareJournal,
  unshareJournalForMe,
  unshareJournalForUsers,
  useJournals,
  useJournalsDetailed,
  useJournalsSharedWithMe,
  useJournalsSharedWithMeDetailed,
} from 'core/api/journals'
import { useCallback } from 'react'
import { UpdateJournalsResponse } from './models'
import { JournalInfo } from 'core/types'
import { useModal } from 'shared/Modal'
import { useCallAndReport } from 'core/hooks/useDoAndReport'

function isValidMessage(value: unknown): value is { message: string } {
  return (
    typeof value === 'object' &&
    value !== null &&
    'message' in value &&
    typeof value.message === 'string'
  )
}

function extractMessage(error: unknown): string | null {
  if (!isValidMessage(error)) return null
  return error.message
}

export function useUpdateJournals() {
  const journals = useJournals()
  const journalsShared = useJournalsSharedWithMe()
  const journalsDetailed = useJournalsDetailed()
  const journalsSharedDetailed = useJournalsSharedWithMeDetailed()

  const updateJournals = useCallback(async () => {
    const response = await Promise.all([
      journals.mutate(),
      journalsShared.mutate(),
      journalsDetailed.mutate(),
      journalsSharedDetailed.mutate(),
    ])

    return {
      journals: response[0],
      journalsShared: response[1],
      journalsDetailed: response[2],
      journalsSharedDetailed: response[3],
    } satisfies UpdateJournalsResponse
  }, [journals, journalsShared, journalsDetailed, journalsSharedDetailed])

  return updateJournals
}

export function useJournalSharing(
  selectJournalId: (journalId: string) => void,
  selectedJournal: JournalInfo | null
) {
  const updateJournals = useUpdateJournals()
  const shareModal = useModal()
  const openShareModal = useCallback(
    (journalId: string) => {
      selectJournalId(journalId)
      shareModal.open()
    },
    [selectJournalId, shareModal]
  )

  const { callAndReport, isLoading } = useCallAndReport()

  const shareSelectedJournal = useCallback(
    async (email: string) => {
      if (!selectedJournal) return
      return callAndReport(
        async () => {
          await shareJournal(selectedJournal.id, [email])
          await updateJournals()
        },
        {
          OK: 'Journal shared',
          400: (resp) => extractMessage(resp),
          404: 'User not found',
          DEFAULT_ERR: 'Failed to share journal',
        }
      )
    },
    [selectedJournal, callAndReport, updateJournals]
  )

  const revokeAccessForUsers = useCallback(
    async (email: string) =>
      callAndReport(
        async () => {
          await unshareJournalForUsers(selectedJournal!.id, [email])
          await updateJournals()
        },
        {
          OK: 'Access revoked',
          DEFAULT_ERR: 'Failed to revoke access for ' + email,
        }
      ),
    [callAndReport, selectedJournal, updateJournals]
  )

  return {
    shareModal,
    openShareModal,
    shareSelectedJournal,
    revokeAccess: revokeAccessForUsers,
    isLoading,
  }
}

export function useJournalDeleting(
  selectJournalId: (journalId: string) => void,
  selectedJournal: JournalInfo | null
) {
  const updateJournals = useUpdateJournals()
  const deleteModal = useModal()

  const openDeleteModal = useCallback(
    (journalId: string) => {
      selectJournalId(journalId)
      return deleteModal.open()
    },
    [deleteModal, selectJournalId]
  )
  const { callAndReport, isLoading } = useCallAndReport()

  const deleteSelectedJournal = useCallback(async () => {
    if (!selectedJournal) return
    return callAndReport(
      async () => {
        await deleteJournal(selectedJournal.id)
        await updateJournals()
      },
      {
        OK: 'Journal deleted',
        404: 'Failed to delete journal. Journal not found',
        DEFAULT_ERR: 'Failed to delete journal',
      },
      () => deleteModal.close()
    )
  }, [selectedJournal, callAndReport, updateJournals, deleteModal])

  return { deleteModal, openDeleteModal, deleteSelectedJournal, isLoading }
}

export function useJournalsEditing(
  selectJournalId: (journalId: string) => void,
  selectedJournal: JournalInfo | null
) {
  const updateJournals = useUpdateJournals()
  const editModal = useModal()
  const { callAndReport, isLoading } = useCallAndReport<JournalInfo>({ rethrowError: true })

  const openEditModal = useCallback(
    (journalId: string) => {
      selectJournalId(journalId)
      editModal.open()
    },
    [editModal, selectJournalId]
  )
  const editSelectedJournal = useCallback(
    async (journalName: string, journalType: string) => {
      if (!selectedJournal) return
      return callAndReport(
        async () => {
          const response = await editJournal(selectedJournal.id, journalName, journalType)
          await updateJournals()
          return response
        },
        { OK: 'Journal updated', DEFAULT_ERR: 'Failed to update journal' }
      )
    },
    [callAndReport, selectedJournal, updateJournals]
  )

  return {
    editModal,
    openEditModal,
    editSelectedJournal,
    isLoading,
  }
}

export function useJournalsRemovingAccess(
  selectJournalId: (journalId: string) => void,
  selectedJournal: JournalInfo | null
) {
  const updateJournals = useUpdateJournals()
  const confirmRevokeAccessForMeModal = useModal()
  const openRevokeAccessForMeModal = useCallback(
    (journalId: string) => {
      selectJournalId(journalId)
      confirmRevokeAccessForMeModal.open()
    },
    [selectJournalId, confirmRevokeAccessForMeModal]
  )

  const { callAndReport, isLoading } = useCallAndReport()

  const revokeAccessForMe = useCallback(async () => {
    if (!selectedJournal) return

    return callAndReport(
      async () => {
        await unshareJournalForMe(selectedJournal.id)
        await updateJournals()
      },
      {
        OK: 'Access revoked',
        DEFAULT_ERR: 'Failed to revoke access for me',
      },
      () => confirmRevokeAccessForMeModal.close()
    )
  }, [callAndReport, selectedJournal, updateJournals, confirmRevokeAccessForMeModal])

  return { revokeAccessForMe, confirmRevokeAccessForMeModal, openRevokeAccessForMeModal, isLoading }
}
