import { ChartSection } from './sections/ChartSection/ChartSection'
import { TradesTableSection } from './sections/TradesTableSection/TradesTableSection'
import './Dashboard.scss'
import { StatisticsSection } from './sections/StatisticsSection/StatisticsSection'
import Filters from './sections/Filters'

export function Dashboard() {
  return (
    <div className="Dashboard">
      <Filters />
      <StatisticsSection />
      <ChartSection label="P&L history" />
      <TradesTableSection />
    </div>
  )
}
