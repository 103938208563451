import { LS_ACCESS_TOKEN, LS_RESTORE_PATH } from 'core/constants'
import './App.scss'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Notifications } from 'shared/Notifications'
import { GlobalTimezoneContextProvider } from 'core/contexts/GlobalTimezoneContext'
import { JournalsApiContextProvider } from 'core/contexts/JournalsApiContext'
import { SWRConfig } from 'swr'

export function App() {
  const navigate = useNavigate()

  useEffect(() => {
    if (
      localStorage.getItem(LS_ACCESS_TOKEN) === null &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/signup'
    ) {
      localStorage.setItem(LS_RESTORE_PATH, window.location.pathname + window.location.search)
      navigate('/login')
    }
  })

  return (
    <div className="App">
      <SWRConfig value={{ revalidateOnMount: true }}>
        <GlobalTimezoneContextProvider>
          <Notifications>
            <JournalsApiContextProvider>
              <Outlet />
            </JournalsApiContextProvider>
          </Notifications>
        </GlobalTimezoneContextProvider>
      </SWRConfig>
    </div>
  )
}

export default App
