import './TradeInfoModal.scss'
import { FlatCard } from 'shared/FlatCard/FlatCard'
import ModalHeader from 'shared/ModalHeader/ModalHeader'
import TextDate from 'shared/TextDate/TextDate'
import TextMoney from 'shared/TextMoney/TextMoney'
import { useContext, useMemo, useState } from 'react'
import { durationToHumanReadable } from 'core/utils'
import { Button } from 'shared/Button'
import Chip from 'shared/Chip'
import InfoTooltip from 'shared/InfoTooltip'
import { TradeItem } from 'core/types'
import { addNote, editNote } from 'core/api/dashboard'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { DashboardApiContext } from 'pages/DashboardPage/DashboardApiContext'
import { TradesTableSectionContext } from '../../TradesTableSectionContext'
import { useUserInfo } from 'core/api/common'
import { useJournalsAll } from 'core/hooks/useJournalsAll'

type TradeInfoDialogProps = {
  onClose: () => void
}
function TradeInfoModal({ onClose }: Readonly<TradeInfoDialogProps>) {
  const { selectedTrade } = useContext(TradesTableSectionContext)

  if (selectedTrade === null)
    return <FlatCard className="TradeInfoDialog">Something went wrong...</FlatCard>

  return (
    <FlatCard className="TradeInfoDialog">
      <ModalHeader label="Trade info" onClose={() => onClose()} />
      <div className="content">
        <TradeInfoGrid tradeItem={selectedTrade} />
        <TradeInfoDialogNoteForm tradeItem={selectedTrade} onClose={() => onClose()} />
      </div>
    </FlatCard>
  )
}

function useTableInfoGridData(selectedTrade: TradeItem | null) {
  return useMemo(() => {
    const dateEntry = new Date(selectedTrade?.timeEntry ?? '')
    const dateExit = new Date(selectedTrade?.timeExit ?? '')
    const durationText =
      selectedTrade?.timeEntry && selectedTrade?.timeExit
        ? durationToHumanReadable(
            new Date(selectedTrade.timeEntry),
            new Date(selectedTrade.timeExit)
          )
        : 'N/A'

    return {
      dateEntry,
      dateExit,
      durationText,
    }
  }, [selectedTrade])
}
type TradeInfoGridProps = { tradeItem: TradeItem }
function TradeInfoGrid({ tradeItem }: Readonly<TradeInfoGridProps>) {
  const { dateEntry, dateExit, durationText } = useTableInfoGridData(tradeItem)

  if (tradeItem === null) return null

  return (
    <div className="TradeInfoGrid">
      <TradeInfoGridCell label="Entry date & time">
        <TextDate date={dateEntry} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Exit date & time">
        <TextDate date={dateExit} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Time in trade">{durationText}</TradeInfoGridCell>

      <TradeInfoGridCell label="Instrument">{tradeItem.instrumentAlias}</TradeInfoGridCell>
      <TradeInfoGridCell label="Max size">{tradeItem.maxSize}</TradeInfoGridCell>
      <TradeInfoGridCell label="Commissions">
        <TextMoney value={tradeItem.commission} />
      </TradeInfoGridCell>

      <TradeInfoGridCell label="Entry price">
        <TextMoney value={tradeItem.priceEntry} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Exit price">
        <TextMoney value={tradeItem.priceExit} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Profit">
        <TextMoney value={tradeItem.profit} colored />
      </TradeInfoGridCell>

      <TradeInfoGridCell label="Type">
        {tradeItem.isBuy ? <Chip color="green">Buy</Chip> : <Chip color="red">Sell</Chip>}
      </TradeInfoGridCell>
      <div className="double-cell">
        <TradeInfoGridCell label="MFE">{tradeItem.mfe}</TradeInfoGridCell>
        <TradeInfoGridCell label="MAE">{tradeItem.mae}</TradeInfoGridCell>
      </div>
      <div className="double-cell">
        <TradeInfoGridCell label="Post MFE">{tradeItem.postMfe ?? 'N/A'}</TradeInfoGridCell>
        <TradeInfoGridCell label="Post MAE">{tradeItem.postMae ?? 'N/A'}</TradeInfoGridCell>
      </div>
    </div>
  )
}
type TradeInfoGridCellProps = { label: string; tooltipText?: string; children: React.ReactNode }
function TradeInfoGridCell({ children, label, tooltipText }: Readonly<TradeInfoGridCellProps>) {
  return (
    <div className="TradeInfoGridCell">
      <div className="label color-secondary-l3 text-description-regular">
        {label} {tooltipText && <InfoTooltip>tooltipText</InfoTooltip>}
      </div>
      <div className="value">{children}</div>
    </div>
  )
}

function useIsNoteEditable(tradeItem: TradeItem) {
  const userInfo = useUserInfo()
  const journalsAll = useJournalsAll()
  const notesJournal = useMemo(() => {
    if (!journalsAll.data) return null
    return journalsAll.data.find((j) => j.id === tradeItem.journalId) ?? null
  }, [journalsAll.data, tradeItem])
  const isNoteFormActive = useMemo(() => {
    if (!userInfo.data || !notesJournal) return false
    return userInfo.data.userEmail === notesJournal.owner.userEmail
  }, [userInfo.data, notesJournal])
  return isNoteFormActive
}
type TradeInfoDialogNoteFormProps = { tradeItem: TradeItem; onClose: () => void }
function TradeInfoDialogNoteForm({ tradeItem, onClose }: Readonly<TradeInfoDialogNoteFormProps>) {
  const [initialValue] = useState(tradeItem.tradeNote)
  const [value, setValue] = useState(tradeItem.tradeNote?.noteText ?? '')
  const { mutateAll } = useContext(DashboardApiContext)

  const isEditable = useIsNoteEditable(tradeItem)

  const clearNoteButtonDisabled = value === ''
  const clearNote = () => setValue('')
  const noteWasChanged = useMemo(() => {
    if (value === '' && initialValue === null) return false
    return value !== initialValue?.noteText
  }, [value, initialValue])

  const { callAndReport, isLoading } = useCallAndReport()
  const submitNote = async () => {
    if (!noteWasChanged) return
    await callAndReport(
      async () => {
        if (initialValue === null) {
          await addNote(value, tradeItem.id)
        } else {
          await editNote(value, initialValue.id)
        }
        await mutateAll()
        onClose()
      },
      {
        OK: 'Note updated',
        DEFAULT_ERR: 'Failed to update note',
      }
    )
  }

  return (
    <div className="TradeInfoDialogNoteForm">
      <div className="form-control">
        <div className="label text-normal-bold">Note</div>
        <textarea
          className="input"
          placeholder="Type your note here..."
          rows={3}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!isEditable}
        />
      </div>

      {isEditable && (
        <div className="buttons">
          <Button
            appearance="outline"
            onClick={() => clearNote()}
            disabled={clearNoteButtonDisabled}
          >
            Clear note
          </Button>
          <Button
            color="primary"
            disabled={!noteWasChanged}
            isLoading={isLoading}
            onClick={() => submitNote()}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default TradeInfoModal
