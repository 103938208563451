import './Tabs.scss'
import { Button } from 'shared/Button'
import { createContext, useContext, useState } from 'react'

type TabsContextData = {
  activeTabId: string | number | null
  setActiveTab: (tabId: string | number) => void
}
export const TabsContext = createContext<TabsContextData>({
  activeTabId: null,
  setActiveTab: () => {
    throw new Error('TabsContext is not provided')
  },
})

type TabButtonProps = {
  tabId: string | number
  children: React.ReactNode
}
function TabButton({ tabId, children }: Readonly<TabButtonProps>) {
  const { activeTabId, setActiveTab } = useContext(TabsContext)
  return (
    <Button
      className={'TabButton ' + (activeTabId === tabId ? 'active' : '')}
      appearance="menu-item"
      onClick={() => setActiveTab(tabId)}
      fontType="text"
    >
      {children}
    </Button>
  )
}

type TabsProps = { children: React.ReactNode; defaultTabId: string | number }
export function Tabs({ children, defaultTabId }: Readonly<TabsProps>) {
  const [activeTabId, setActiveTabId] = useState<string | number | null>(defaultTabId ?? null)

  return (
    <TabsContext.Provider value={{ activeTabId, setActiveTab: setActiveTabId }}>
      <div className="Tabs">{children}</div>
    </TabsContext.Provider>
  )
}
type TabsContentProps = { children: React.ReactNode; tabId: string | number }
function TabContent({ children, tabId }: Readonly<TabsContentProps>) {
  const { activeTabId } = useContext(TabsContext)
  if (activeTabId !== tabId) return null
  return <div className="TabsContent">{children}</div>
}
function TabsButtons({ children }: Readonly<{ children: React.ReactNode }>) {
  return <div className="TabsButtons">{children}</div>
}

Tabs.Content = TabContent
Tabs.Buttons = TabsButtons
Tabs.Button = TabButton

export default Tabs
