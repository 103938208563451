type ItemTextProps = { primary: string; secondary: string }
export function DropdownItemText({ primary, secondary }: Readonly<ItemTextProps>) {
  return (
    <div className="DropdownItemText">
      <div className="DropdownItemText__primary text-normal-regular">{primary}</div>
      {secondary && (
        <div className="DropdownItemText__secondary text-description-regular">{secondary}</div>
      )}
    </div>
  )
}
