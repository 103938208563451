import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'

type TableRowProps = {
  children: React.ReactNode
  className?: string
} & AllHTMLAttributes<HTMLTableRowElement>
/**
 * Represents a row in a Table. If `onClick` is provided, the row will have hover effect.
 *
 * Usage:
 * ```tsx
 * <Table.Row onClick={() => console.log('Row clicked')}>
 *  <Table.Cell>Cell 1</Table.Cell>
 *  <Table.Cell>Cell 2</Table.Cell>
 * </Table.Row>
 * ```
 * @component
 */
export default function TableRow({
  children,
  onClick,
  className,
  ...rest
}: Readonly<TableRowProps>) {
  return (
    <tr
      className={classNames('TableRow', onClick && 'hoverable', className)}
      onClick={(e) => onClick && onClick(e)}
      {...rest}
    >
      {children}
    </tr>
  )
}
