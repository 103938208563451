import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'

type TableCellProps = {
  children: React.ReactNode
  bold?: boolean
  stopPropagation?: boolean
} & AllHTMLAttributes<HTMLTableCellElement>
function TableCell({
  children,
  className,
  bold,
  stopPropagation,
  onClick,
  ...rest
}: Readonly<TableCellProps>) {
  const handleOnClick = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    stopPropagation && event.stopPropagation()
    onClick && onClick(event)
  }

  return (
    <td
      className={classNames('TableCell ', className, bold && 'bold')}
      onClick={(event) => handleOnClick(event)}
      {...rest}
    >
      {children}
    </td>
  )
}

function MenuCell({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <TableCell className="MenuCell" stopPropagation>
      {children}
    </TableCell>
  )
}

TableCell.Menu = MenuCell
export default TableCell
