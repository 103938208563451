import { Button } from 'shared/Button'
import './Pagination.scss'

const PAGES_MAX = 9

const getButtonsArray = (pagesTotal: number, currentPage: number): (number | null)[] => {
  if (pagesTotal <= PAGES_MAX) return new Array(pagesTotal).fill(0).map((v, i) => i)

  const fullArray = new Array(pagesTotal).fill(0).map((v, i) => i)

  let sliceStart: number
  let sliceEnd: number

  const SLICE_LIMIT = {
    MIN: 1,
    MAX: fullArray.length - 1,
  }
  const INNER_SLICE_SIZE = PAGES_MAX - 2
  const INNER_SLICE_HALF = Math.round(INNER_SLICE_SIZE / 2)

  if (currentPage < INNER_SLICE_HALF + 1) {
    sliceStart = SLICE_LIMIT.MIN
    sliceEnd = sliceStart + INNER_SLICE_SIZE
  } else if (fullArray.length - currentPage - 2 < INNER_SLICE_HALF) {
    sliceEnd = SLICE_LIMIT.MAX
    sliceStart = sliceEnd - INNER_SLICE_SIZE
  } else {
    sliceStart = currentPage - INNER_SLICE_HALF + 1
    sliceEnd = currentPage + INNER_SLICE_HALF
  }

  const innerArray: (number | null)[] = fullArray.slice(sliceStart, sliceEnd)
  if (innerArray.at(0) !== 1) innerArray[0] = null
  if (innerArray.at(-1) !== fullArray.at(-2)) innerArray[innerArray.length - 1] = null

  const result = [fullArray.at(0) ?? null, ...innerArray, fullArray.at(-1) ?? null]

  return result
}

type Props = { currentPage: number; pagesTotal: number; requestPage: (page: number) => void }
export function Pagination({ currentPage, pagesTotal, requestPage }: Props) {
  const numbersArr = getButtonsArray(pagesTotal, currentPage)

  return (
    <div className="Pagination">
      <Button
        appearance="text"
        className="arrow-button"
        square
        disabled={currentPage === 0}
        onClick={() => requestPage(currentPage - 1)}
      >
        &lt;
      </Button>
      {numbersArr.map((value, index) => {
        if (value === null)
          return (
            <div className="pag-button pag-button-empty" key={'...' + index}>
              ...
            </div>
          )
        return (
          <Button
            appearance={value === currentPage ? 'solid' : 'text'}
            square
            key={value}
            onClick={() => requestPage(value)}
          >
            {value + 1}
          </Button>
        )
      })}
      {pagesTotal ? null : (
        <Button appearance="text" square className="pag-button" disabled>
          1
        </Button>
      )}

      <Button
        appearance="text"
        square
        className="arrow-button menu-button"
        disabled={currentPage >= pagesTotal - 1}
        onClick={() => requestPage(currentPage + 1)}
      >
        &gt;
      </Button>
    </div>
  )
}
