import './Icon.scss'
import { AllHTMLAttributes } from 'react'
import ICONS_MAP from './icon-map'
import { IconName } from './models'
import classNames from 'classnames'

type IconProps = { name: IconName; rotate?: '0' | '90' | '180' | '270' } & Omit<
  AllHTMLAttributes<HTMLImageElement>,
  'src'
>
export function Icon({ name, className, rotate, ...props }: Readonly<IconProps>) {
  const path = ICONS_MAP[name]
  return (
    <img
      className={classNames('Icon', rotate ? `rotate-${rotate}` : '', className ?? '')}
      {...props}
      alt={props.alt}
      src={path}
    />
  )
}
