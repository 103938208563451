import { AllHTMLAttributes } from 'react'
import './FlatCard.scss'
import classNames from 'classnames'

type FlatCardProps = Readonly<{
  children?: React.ReactNode
}> &
  AllHTMLAttributes<HTMLDivElement>
export function FlatCard({ children, className, ...props }: FlatCardProps) {
  return (
    <div className={classNames('FlatCard', className ?? '')} {...props}>
      {children}
    </div>
  )
}
