import { AllHTMLAttributes } from 'react'

type DropdownActionItemsSectionProps = AllHTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
}
export function DropdownFooterSection({
  children,
  ...rest
}: Readonly<DropdownActionItemsSectionProps>) {
  return (
    <div className="DropdownFooterSection" {...rest}>
      {children}
    </div>
  )
}
