export type TimezoneItem = {
  regions: Set<string>
  pickTimezone: string
  zones: Set<string>
  timezoneOffsetName: string
  offsetMs: number
  offsetMsRelative: number
  abbreviations?: string[]
}
export const blankTimezoneItem: TimezoneItem = {
  regions: new Set(),
  pickTimezone: '',
  timezoneOffsetName: '',
  offsetMs: 0,
  offsetMsRelative: 0,
  zones: new Set(),
}

export type GlobalTimezoneContextData = {
  timeZone: TimezoneItem
  setTimeZone: React.Dispatch<React.SetStateAction<TimezoneItem>>
  availableTimeZonesNameOptions: TimezoneItem[]
  dateFormatter: Intl.DateTimeFormat
  timeFormatter: Intl.DateTimeFormat
  getDateTimeFormatter: (options: Intl.DateTimeFormatOptions) => Intl.DateTimeFormat
}
export const blankGlobalTimezoneContextData: GlobalTimezoneContextData = {
  timeZone: {
    regions: new Set(),
    pickTimezone: '',
    timezoneOffsetName: '',
    offsetMs: 0,
    offsetMsRelative: 0,
    zones: new Set(),
  },
  setTimeZone: () => {},
  availableTimeZonesNameOptions: [],
  dateFormatter: new Intl.DateTimeFormat(),
  timeFormatter: new Intl.DateTimeFormat(),
  getDateTimeFormatter: () => new Intl.DateTimeFormat(),
}
