import React, { AllHTMLAttributes } from 'react'
import './Table.scss'
import classNames from 'classnames'
import TableBody from './components/TableBody'
import TableRow from './components/TableRow'
import TableHeaderCell from './components/TableHeaderCell'
import { TableHeader } from './components/TableHeader'
import TableCell from './components/TableCell'

type TableProps = {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  tableNeighbor?: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
/**
  Usage:
  ```tsx
  <Table>
    <Table.Header>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
        <Table.HeaderCell.Empty />
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Milk</Table.Cell>
        <Table.Cell>$2</Table.Cell>
        <Table.Cell.Menu>
          <button>⋮</button>
        </Table.Cell.Menu>
      </Table.Row>
    </Table.Body>
  </Table>
    ```

  @component
*/
function Table({ children, style, className, tableNeighbor, ...rest }: Readonly<TableProps>) {
  return (
    <div className={classNames('Table', className)} {...rest}>
      <table>{children}</table>
      {tableNeighbor}
    </div>
  )
}

/**
 * Represents a header cell in a table.
 */
Table.HeaderCell = TableHeaderCell
Table.Header = TableHeader
Table.Row = TableRow
Table.Body = TableBody
Table.Cell = TableCell

export default Table
