import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import TextMoney from 'shared/TextMoney'

export function ChartTooltip({ date, value }: { date?: Date; value?: string }) {
  const { dateFormatter, timeFormatter } = useGlobalTimezone()

  return (
    <div className="tooltip">
      <div className="value">
        <TextMoney value={parseFloat(value ?? '0')} />
      </div>
      {/* <div className="value">{formatMoney(parseFloat(numValue.toFixed(2)), '$', 2)}</div> */}
      <div className="date">
        <span>{dateFormatter.format(date)}</span>
        &nbsp;
        <span>{timeFormatter.format(date)}</span>
      </div>
    </div>
  )
}
