import { PnlItem } from 'core/types'
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ChartTooltip } from './ChartTooltip'
import { ChartItem } from './ChartSection'
import { formatMoney } from 'core/utils'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { useMemo } from 'react'

type Props = { items: ChartItem[] }
export function Chart({ items }: Readonly<Props>) {
  const dataMax = Math.max(...items.map((i) => i.value))
  const dataMin = Math.min(...items.map((i) => i.value))

  const gradientOffset = () => {
    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1
    return dataMax / (dataMax - dataMin)
  }

  const { getDateTimeFormatter } = useGlobalTimezone()
  const dateFormatter = useMemo(
    () => getDateTimeFormatter({ month: 'numeric', day: 'numeric' }),
    [getDateTimeFormatter]
  )

  const axisYSize: () => number = () => {
    const ABS = Math.max(dataMax, Math.abs(dataMin))
    if (ABS < 100) return 60
    if (ABS < 1000) return 65
    if (ABS < 1e6) return 85
    if (ABS < 1e9) return 110
    if (ABS < 1e12) return 130
    if (ABS < 1e16) return 150
    return 160
  }

  const off = gradientOffset()

  return (
    <ResponsiveContainer>
      <AreaChart data={items} margin={{ right: 12 }}>
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#30ee53" stopOpacity={1} />
            <stop offset={off} stopColor="#30ee53" stopOpacity={0.1} />
            <stop offset={off} stopColor="#f53b54" stopOpacity={0.1} />
            <stop offset="100%" stopColor="#f53b54" stopOpacity={1} />
          </linearGradient>
        </defs>

        {dataMin < 0 && <ReferenceLine y={0} stroke="#39525b" />}

        <Area
          animationDuration={0}
          type="step"
          dataKey="value"
          stroke="#fff"
          fill="url(#splitColor)"
        />

        <YAxis
          style={{ fontSize: '12px', fill: '#9EACB2' }}
          padding={{ top: 20, bottom: 20 }}
          stroke="#39525B"
          width={axisYSize()}
          tickFormatter={formatYTick}
        />
        <XAxis
          style={{ fontSize: '12px', fill: '#9EACB2' }}
          padding={{ left: 20, right: 20 }}
          stroke="#39525B"
          minTickGap={50}
          dataKey="time"
          tickFormatter={(value: string) => dateFormatter.format(new Date(value))}
        />
        <Tooltip content={tooltipRenderer} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

function tooltipRenderer(...args: any[]) {
  const payload: PnlItem | undefined = (args as any)[0]?.payload[0]?.payload

  if (!payload) return
  return ChartTooltip({ date: new Date(payload.time), value: payload.value.toString() })
}

const formatYTick = (value: any) => {
  return formatMoney(value)
}
