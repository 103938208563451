import { LocalSortingSchema, useLocalSorting } from 'core/hooks/useLocalSorting'
import { JournalDetailedInfo } from 'core/types'
import { useMemo } from 'react'

export function useJournalSorting() {
  const schema: LocalSortingSchema<JournalDetailedInfo> = useMemo(() => {
    return {
      journalName: (a, b) => a.localeCompare(b),
      totalPnl: (a, b) => a - b,
      totalTrades: (a, b) => a - b,
      maxDrawdown: (a, b) => a - b,
      sharedWith: (a, b) => a.length - b.length,
      owner: (a, b) => a.userEmail.localeCompare(b.userEmail),
    } satisfies LocalSortingSchema<JournalDetailedInfo>
  }, [])

  return useLocalSorting<JournalDetailedInfo>('journalName', schema)
}
