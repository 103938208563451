import { TimezoneItem } from './models'
import { INITIAL_FORMATTING_OPTIONS, TIMEZONE_LS_TOKEN, TIMEZONE_OFFSET_MAP } from './constants'

/** GMT+XX:XX > UTC+XX:XX */
function formatTimezoneOffsetName(offset: string) {
  if (offset === 'GMT') return '+00:00'
  return offset.replace('GMT', '')
}

export const getInitialTimezoneItem = (): TimezoneItem => {
  const fallbackTimezoneItem = {
    regions: new Set(),
    pickTimezone: INITIAL_FORMATTING_OPTIONS.timeZone,
    timezoneOffsetName: formatTimezoneOffsetName(
      new Intl.DateTimeFormat('en-GB', {
        timeZone: INITIAL_FORMATTING_OPTIONS.timeZone,
        timeZoneName: 'longOffset',
      })
        .format(new Date(0))
        .split(' ')[1]
    ),
    comparableValue: 0,
    zones: new Set(),
  }
  const restoredTimezoneItem = JSON.parse(localStorage.getItem(TIMEZONE_LS_TOKEN) ?? 'null')

  return (
    TIMEZONE_OFFSET_MAP[
      restoredTimezoneItem?.timezoneOffsetName ?? fallbackTimezoneItem.timezoneOffsetName
    ] ?? fallbackTimezoneItem
  )
}

export function calcTimezoneOffset(timezoneNamesArr: string[]): Record<string, string> {
  const map = Object.fromEntries(
    timezoneNamesArr.map((tzName) => [
      tzName,
      formatTimezoneOffsetName(
        new Intl.DateTimeFormat('en-GB', { timeZone: tzName, timeZoneName: 'longOffset' })
          .format(new Date(0))
          .split(' ')[1]
      ),
    ])
  )

  return map
}

export function calcTimezoneOffsetMap(
  timezoneNamesArr: string[],
  initialTimezoneResolvedObject: Intl.ResolvedDateTimeFormatOptions,
  abbreviationsMap: Record<string, string[]>
): [{ [timezoneName: string]: TimezoneItem }, TimezoneItem | undefined] {
  const getTimeFormatter = (tzName: string) =>
    new Intl.DateTimeFormat('en-GB', {
      timeZone: tzName,
      timeZoneName: 'longOffset',
    })

  const resultMap: Record<string, TimezoneItem> = {}
  let initialTimezoneObject: TimezoneItem | undefined

  timezoneNamesArr.forEach((tzName) => {
    const currentFormatter = getTimeFormatter(tzName)
    const tzOffsetName /* GMT+XX:XX */ = formatTimezoneOffsetName(
      currentFormatter.format(new Date()).split(' ')[1]
    )

    if (!resultMap[tzOffsetName]) {
      const dateString = '01.01.1970 ' + tzOffsetName.slice(1) + ' UTC'
      const comparableDate = new Date(dateString)
      const offsetMs = comparableDate.getTime() * (tzOffsetName.startsWith('+') ? 1 : -1)
      const currentOffsetMs = new Date().getTimezoneOffset() * 60 * 1000 * -1
      const offsetMsRelative = offsetMs - currentOffsetMs

      resultMap[tzOffsetName] = {
        regions: new Set(),
        zones: new Set(),
        pickTimezone: tzName,
        timezoneOffsetName: tzOffsetName,
        offsetMs,
        offsetMsRelative,
      }
    }
    resultMap[tzOffsetName].regions.add(tzName.split('/')[0] ?? tzName)
    resultMap[tzOffsetName].zones.add(tzName)

    if (tzName === initialTimezoneResolvedObject.timeZoneName) {
      initialTimezoneObject = resultMap[tzOffsetName]
    }
  })

  Object.keys(resultMap).forEach((timezoneOffsetName) => {
    const tz = resultMap[timezoneOffsetName]
    const abbreviations = abbreviationsMap[tz.timezoneOffsetName]
    if (abbreviations && abbreviations.length > 0) {
      tz.abbreviations = abbreviations
    }
  })

  return [resultMap, initialTimezoneObject]
}

// function tmpFormatMsAsTime(ms: number): string {
//   return `${ms / 1000 / 60}m (${ms / 1000 / 60 / 60}h)`
// }
