import { ReactNode } from 'react'
import { AllowedTradeKeys } from '../../../TradesTableSectionContext/models'
import { TradeItem } from 'core/types'
import Table from 'shared/Table'
import TextMoney from 'shared/TextMoney'
import Chip from 'shared/Chip'
import TextDate from 'shared/TextDate'

const renderCellAs = {
  date: (value: string | null, key: string) => (
    <Table.Cell key={key}>{value ? <TextDate date={new Date(value)} /> : '-'}</Table.Cell>
  ),
  money: (value: number | null, key: string) => (
    <Table.Cell key={key}>
      <TextMoney value={value} />
    </Table.Cell>
  ),
  moneyColored: (value: number | null, key: string) => (
    <Table.Cell key={key}>
      <TextMoney value={value} colored />
    </Table.Cell>
  ),
  simple: (value: number | string | null, key: string) => (
    <Table.Cell key={key}>{value ?? '-'}</Table.Cell>
  ),
  chip: (value: boolean | null, key: string) => (
    <Table.Cell key={key}>
      {value ? <Chip className="bg-green">Buy</Chip> : <Chip className="bg-red">Sell</Chip>}
    </Table.Cell>
  ),
  note: (value: TradeItem['tradeNote']) => <NoteCell noteItem={value} />,
}

type NoteCellProps = { noteItem: TradeItem['tradeNote'] }
function NoteCell({ noteItem }: Readonly<NoteCellProps>) {
  return (
    <Table.Cell className="NoteCell">
      {noteItem === null || noteItem.noteText === '' ? (
        <div className="text empty"> - </div>
      ) : (
        <abbr title={noteItem.noteText} className="text">
          {noteItem.noteText}
        </abbr>
      )}
    </Table.Cell>
  )
}

type CellRenderMap = { [K in AllowedTradeKeys]: (value: TradeItem[K], key: string) => ReactNode }
export const TRADES_TABLE_CELLS_RENDER_MAP: CellRenderMap = {
  commission: renderCellAs.money,
  fills: renderCellAs.simple,
  instrumentAlias: renderCellAs.simple,
  isBuy: renderCellAs.chip,
  mae: renderCellAs.simple,
  maxSize: renderCellAs.simple,
  mfe: renderCellAs.simple,
  postMae: renderCellAs.simple,
  postMfe: renderCellAs.simple,
  priceEntry: renderCellAs.money,
  priceExit: renderCellAs.money,
  profit: renderCellAs.moneyColored,
  timeEntry: renderCellAs.date,
  timeExit: renderCellAs.date,
}
