import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import './SimpleDropdown.scss'
import Floating from 'shared/Floating'
import { DropdownItem, ItemProps } from '../components/DropdownItem'
import { DropdownDivider } from '../components/DropdownDivider'
import { DropdownItemText } from '../components/DropdownItemText'
import { DropdownActionItem, DropdownActionItemProps } from '../components/DropdownActionItem'
import { DropdownTrigger } from '../components/DropdownTrigger'
import classNames from 'classnames'
import { DropdownFooterSection } from '../components/DropdownActionItemsSection'

type DropdownProps<T> = {
  children?: ReactNode
  value?: T
  id?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  tooltipContent?: ReactNode
  onChange?: (value: T) => void
  onBlur?: () => void
  getVisualValue?: (value: T) => ReactNode
}
export function SimpleDropdown<T>({
  children,
  value,
  id,
  onChange,
  className,
  onBlur,
  tooltipContent,
  getVisualValue = (v) => String(v),
  placeholder = 'Select an option',
  disabled,
}: Readonly<DropdownProps<T>>) {
  const [opened, setOpened] = useState(false)
  const [minWidth, setMinWidth] = useState(100)

  const triggerRef = React.useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (triggerRef.current === null) return
    const rect = triggerRef.current.getBoundingClientRect()
    setMinWidth(rect.width)
  }, [opened])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelect = (value: T) => {
    onChange && onChange(value)
    onBlur && onBlur()
    setOpened(false)
  }

  const renderedChildren = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child
      if (child.type === DropdownItem) {
        return React.cloneElement(child, {
          ...child.props,
          selected: value === child.props.value,
          onSelect: (value) => handleSelect(value),
        } satisfies ItemProps<T>)
      }
      if (child.type === DropdownActionItem) {
        return React.cloneElement(child, {
          ...child.props,
          onCloseMenu: () => setOpened(false),
        } satisfies DropdownActionItemProps)
      }

      return child
    })
  }, [children, value, handleSelect])

  const getScrollToElementCb = useCallback((floatingContainer: HTMLElement): HTMLElement | null => {
    return floatingContainer.querySelector('.DropdownItem--selected')
  }, [])

  return (
    <div className={classNames('SimpleDropdown', className, disabled && 'disabled')} id={id}>
      <Floating
        opened={opened}
        setOpened={setOpened}
        minWidth={minWidth}
        getScrollToElementCb={getScrollToElementCb}
        trigger={
          <DropdownTrigger
            ref={triggerRef}
            opened={opened}
            placeholder={placeholder}
            tooltipContent={tooltipContent}
            value={value !== undefined && value !== '' ? getVisualValue(value) : undefined}
          />
        }
        content={<div className="SimpleDropdown__content">{renderedChildren}</div>}
      />
    </div>
  )
}

SimpleDropdown.Item = DropdownItem
SimpleDropdown.ActionItem = DropdownActionItem
SimpleDropdown.ItemText = DropdownItemText
SimpleDropdown.Divider = DropdownDivider
SimpleDropdown.FooterSection = DropdownFooterSection
export default SimpleDropdown
