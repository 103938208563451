import { TradeItem } from 'core/types'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import Menu from 'shared/Menu'
import TableCell from 'shared/Table/components/TableCell'
import { TradesTableSectionContext } from '../../../TradesTableSectionContext'
import { useContext } from 'react'

type MenuCellProps = { item: TradeItem }
export function MenuCell({ item }: Readonly<MenuCellProps>) {
  const { openAddNoteModal, openMoveTradeToModal, openConfirmDeleteTradeModal } =
    useContext(TradesTableSectionContext)

  return (
    <TableCell.Menu>
      <div className="center">
        <Menu
          trigger={
            <Button appearance="text" size="small" square>
              <Icon name="DotsVertical" />
            </Button>
          }
        >
          {item.tradeNote === null || item.tradeNote.noteText === '' ? (
            <Menu.Item onClick={() => openAddNoteModal(item)}>Add note</Menu.Item>
          ) : (
            <Menu.Item onClick={() => openAddNoteModal(item)}>Edit note</Menu.Item>
          )}
          {/* <Menu.Item>Clear note</Menu.Item> */}
          {item.isLocked === false && (
            <>
              <Menu.Divider />
              <Menu.Item onClick={() => openMoveTradeToModal(item)}>Move to...</Menu.Item>
              <Menu.Item className="red" onClick={() => openConfirmDeleteTradeModal(item)}>
                Delete trade
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </TableCell.Menu>
  )
}
