import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import './TextDate.scss'

type TextDateProps = { date?: Date | null; fallbackText?: string }
function TextDate({ date, fallbackText }: Readonly<TextDateProps>) {
  const { dateFormatter, timeFormatter } = useGlobalTimezone()

  if (!date) return <span className="TextDate">{fallbackText ?? 'N/A'}</span>

  try {
    dateFormatter.format(date)
  } catch (err) {
    console.error('CANT PARSE DATE', date, err)
  }

  return (
    <span className="TextDate">
      <span className="date">{date !== null ? dateFormatter.format(date) : '-'}</span>
      &nbsp;&nbsp;
      <span className="time">{date !== null ? timeFormatter.format(date) : '-'}</span>
    </span>
  )
}

export default TextDate
