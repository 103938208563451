import classNames from 'classnames'
import './TextMoney.scss'
import { formatMoney } from 'core/utils'
import { HTMLAttributes, useMemo } from 'react'

type TextMoneyProps = {
  value?: number | string | null
  colored?: boolean
  fallback?: string
} & HTMLAttributes<HTMLSpanElement>
function TextMoney({
  value,
  colored,
  className,
  fallback = 'N/A',
  ...rest
}: Readonly<TextMoneyProps>) {
  const finalValue = +(value ?? 0)
  const formattedValue = useMemo(() => !isNaN(finalValue) && formatMoney(finalValue), [finalValue])
  const colorClass = useMemo(() => {
    if (!colored || isNaN(finalValue) || finalValue === 0) return ''
    if (finalValue < 0) return 'negative'
    return 'positive'
  }, [colored, finalValue])

  return (
    <span className={classNames('TextMoney', colorClass, className ?? '')} {...rest}>
      {formattedValue || fallback}
    </span>
  )
}

export default TextMoney
