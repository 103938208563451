export function getDatesToPick(relativeTimezoneOffsetMs: number, baseDate: Date): Date[] {
  const dates: Date[] = []

  const getInitialDate = () => {
    let initialDate = new Date(baseDate.getTime())
    initialDate.setHours(0, 0, 0, 0)
    initialDate = new Date(initialDate.getTime() - relativeTimezoneOffsetMs)
    return initialDate
  }

  const initial = getInitialDate()

  for (let i = 0; i < 24 /* * 4*/; i++) {
    const result = new Date(initial.getTime() + i * 60 * 60 * 1000)
    dates.push(result)
  }

  return dates
}

export function compareTime(a: Date, b: Date): boolean {
  return a.getMinutes() === b.getMinutes() && a.getHours() === b.getHours()
}

export function getInitialTime() {
  return new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000)
}

/**
 * Returns the number of days to add to the date to get the correct date in the timezone.
 *
 * Why do we need this? After merging the date and some time values, resulting date will be in right
 * day in OS's timezone, but not in the timezone user selected. This function calculates the difference
 */
export function calcRequiredOffsetDays(date: Date, timezoneOffsetMs: number) {
  const dateInTimezone = new Date(date.getTime() + timezoneOffsetMs)
  const delta = date.getDate() - dateInTimezone.getDate()

  /* Difference may be 1 if timezone offset covers 2 days within 1 month 
     (e.g Sep 4 Δ Sep 5 = 1) 
     or > 2 when covers 2 days within different mouths
     (e.g Dec 31 Δ Jan 1 = 30) */
  if (delta === -1 || delta > 2) return 1
  if (delta === 1 || delta < -2) return -1
  return 0
}
