import { Button } from 'shared/Button'
import './ModalHeader.scss'
import { IconsSrc } from 'core/assets'

type ModalHeaderProps = { label: string; onClose: () => void }
function ModalHeader({ label, onClose }: Readonly<ModalHeaderProps>) {
  return (
    <div className="ModalHeader">
      <div className="label text-big-bold">{label}</div>
      <Button appearance="text" size="small" square onClick={() => onClose()}>
        <img src={IconsSrc.Cross} alt="Cross" />
      </Button>
    </div>
  )
}

export default ModalHeader
