import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { useNotifications } from 'shared/Notifications'

type CallAndReportActionMessage<R = string> = string | null | ((response: unknown) => R)
type CallAndReportStatusMap = Record<
  number,
  string | CallAndReportActionMessage<string | null | undefined>
> & {
  /** Message on success */
  OK: string
  /** Fallback message for missing status codes */
  DEFAULT_ERR: CallAndReportActionMessage<string>
  /** Fallback message for case when not axios used */
  UNKNOWN_ERR?: string
}

type CallAndReportHookResponse<T> = {
  /** Make HTTP request & report to notifications  */
  callAndReport: CallAndReportFunction<T>
  isLoading: boolean
}

type CallAndReportFunction<T> = (
  action: () => Promise<T>,
  statusMap: CallAndReportStatusMap,
  finallyCb?: () => void
) => Promise<T | undefined>

const callActionMessage = (
  message: CallAndReportActionMessage<string | null | undefined>,
  argument: unknown
) => {
  if (message === null || typeof message === 'string') return message
  return message(argument)
}

type CallAndReportConfig = {
  rethrowError?: boolean
}

/** Calls action callback & send notification according to status map. Loading state included */
export function useCallAndReport<T = void>({
  rethrowError = false,
}: CallAndReportConfig = {}): CallAndReportHookResponse<T> {
  const [isLoading, setIsLoading] = useState(false)
  const { showNotification } = useNotifications()

  /** Show error by error status code or default error or fallback error */
  const handleError = useCallback(
    (error: unknown, statusMap: CallAndReportStatusMap) => {
      const unknownErr = statusMap.UNKNOWN_ERR ?? 'Unknown error'
      if (!(error instanceof AxiosError) || !error.status) {
        showNotification({ text: unknownErr, type: 'error' })
        throw error
      }

      const actionMessage =
        statusMap[error.status] !== undefined ? statusMap[error.status] : statusMap.DEFAULT_ERR
      const text: string | null | undefined = callActionMessage(actionMessage, error.response?.data)
      if (text !== null) showNotification({ text: text ?? unknownErr, type: 'error' })
      throw error
    },
    [showNotification]
  )

  const callAndReport: CallAndReportFunction<T> = useCallback(
    async (
      action: () => Promise<T>,
      statusMap: CallAndReportStatusMap,
      finallyCb?: () => void
    ): Promise<T | undefined> => {
      setIsLoading(true)
      try {
        const result = await action()
        showNotification({ text: statusMap.OK, type: 'success' })
        return result
      } catch (err) {
        handleError(err, statusMap)
        if (rethrowError) throw err
      } finally {
        setIsLoading(false)
        finallyCb && finallyCb()
      }
    },
    [handleError, rethrowError, showNotification]
  )

  return { callAndReport, isLoading }
}
