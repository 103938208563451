import imgInfo from 'assets/textures/info.svg'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  arrow,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react'
import { useRef, useState } from 'react'

import './InfoTooltip.scss'

type InfoTooltipProps = { children: React.ReactNode; trigger?: React.ReactNode }
export function InfoTooltip({ children, trigger }: InfoTooltipProps) {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef<HTMLDivElement>(null)

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow({ element: arrowRef }),
    ],
  })

  // Event listeners to change the open state
  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  return (
    <div className="InfoTooltip">
      <div className="InfoTooltip__trigger" ref={refs.setReference} {...getReferenceProps()}>
        {trigger || <img src={imgInfo} alt="Info" />}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            className="InfoTooltip__container"
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            <div className="InfoTooltip__container__content">{children}</div>
            <div
              className="InfoTooltip__container__arrow"
              ref={arrowRef}
              style={{
                position: 'absolute',
                left: middlewareData.arrow?.x ?? 0,
                top: middlewareData.arrow?.y,
              }}
            >
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 8L12 0H0L6 8Z" fill="#2D3E43" />
              </svg>
            </div>
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}
