import { useUserInfo } from 'core/api/common'
import { JournalInfo } from 'core/types'
import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { useContext, useEffect, useMemo, useState } from 'react'

export const useScrolling = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => setIsScrolled(document.documentElement.scrollTop > 90)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isScrolled }
}

export const useTooltipText = (selectedJournal: JournalInfo | null) => {
  const userInfo = useUserInfo()
  const { filters } = useContext(FilterContext)

  const shareButtonTooltipContent = useMemo(() => {
    if (!selectedJournal) return 'You need to select a journal'
    if (selectedJournal?.owner.userEmail !== userInfo.data?.userEmail)
      return 'You can only share your own journals'
    return 'Share selected journal with someone'
  }, [selectedJournal, userInfo.data?.userEmail])

  const selectedInstrumentsTooltipContent = useMemo(() => {
    if (filters.symbols.length === 0) return 'All instruments are selected'
    return 'Selected instruments: ' + filters.symbols.join(', ')
  }, [filters.symbols])

  const selectedJournalTooltipContent = useMemo(() => {
    if (!selectedJournal) return 'All journals are selected'
    return 'Selected journal: ' + selectedJournal.journalName
  }, [selectedJournal])

  return {
    shareButtonTooltipContent,
    selectedInstrumentsTooltipContent,
    selectedJournalTooltipContent,
  }
}

// const useResolveEmptyJournalFilter = () => {
//   const { filters, setFilters } = useContext(FilterContext)
//   const journalsAll = useJournalsAll()

//   useEffect(() => {
//     if (filters.journal !== '' && filters.journal !== null) return
//     if (journalsAll.isLoading) return
//     if (journalsAll.data === undefined) return
//     if (journalsAll.data.length === 0) return
//     const journal = journalsAll.data[0]
//     setFilters((filters) => ({ ...filters, journal: journal.id }))
//   }, [filters.journal, journalsAll.data, journalsAll.isLoading, setFilters])
// }
