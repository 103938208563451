import { JournalInfo, TradeItem } from 'core/types'
import { useContext, useMemo, useState } from 'react'
import AddEditNoteModal from '../modals/AddEditNoteModal'
import TradeInfoModal from '../modals/TradeInfoModal/TradeInfoModal'
import { SelectJournalDialog } from '../modals/MoveToJournalModal/MoveToJournalModal'
import { Modal, useModal } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { TradesTableSectionContextData } from './models'
import { TradesTableSectionContext } from './TradesTableSectionContext'
import { useTradesTableApi, useTradesTableHeaders } from './hooks'
import { TableConfigModal } from '../modals/TableConfigModal/TableConfigModal'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import { DashboardApiContext } from 'pages/DashboardPage/DashboardApiContext'

type TradesTableSectionContextProps = {
  children: React.ReactNode
}
export function TradesTableSectionContextProvider({
  children,
}: Readonly<TradesTableSectionContextProps>) {
  const [selectedTrade, setSelectedTrade] = useState<TradeItem | null>(null)

  const { deleteTrade, isLoading, moveTrade } = useTradesTableApi()
  const { columns, isColumSelectedMap, setColumnsKeys } = useTradesTableHeaders()

  const { updateJournals: onUpdateJournals } = useJournalsApi()
  const { mutateAll: onUpdateDashboard } = useContext(DashboardApiContext)

  const addEditNoteModal = useModal()
  const confirmDeleteModal = useModal()
  const selectJournalModal = useModal()
  const tradeInfoModal = useModal()
  const tableConfigModal = useModal()

  const providerValue = useMemo(() => {
    return {
      selectedTrade,
      setSelectedTrade,
      openAddNoteModal: (item) => {
        item && setSelectedTrade(item)
        addEditNoteModal.open()
      },
      openTradeInfoModal: (item) => {
        item && setSelectedTrade(item)
        tradeInfoModal.open()
      },
      openMoveTradeToModal: (item: TradeItem) => {
        setSelectedTrade(item)
        selectJournalModal.open()
      },
      openConfirmDeleteTradeModal: (item) => {
        setSelectedTrade(item)
        confirmDeleteModal.open()
      },
      openTableConfigModal: () => {
        tableConfigModal.open()
      },
      columns,
      isColumSelectedMap,
      setColumnsKeys,
    } satisfies TradesTableSectionContextData
  }, [
    selectedTrade,
    addEditNoteModal,
    tradeInfoModal,
    selectJournalModal,
    confirmDeleteModal,
    columns,
    isColumSelectedMap,
    setColumnsKeys,
    tableConfigModal,
  ])

  const deleteSelectedTrade = async () => {
    if (selectedTrade === null) return
    try {
      await deleteTrade(selectedTrade)
    } catch (e) {}

    onUpdateDashboard()
    onUpdateJournals()
    confirmDeleteModal.close()
  }

  const moveSelectedTrade = async (journal: JournalInfo) => {
    if (selectedTrade === null) return
    try {
      await moveTrade(selectedTrade, journal)
    } catch (e) {}
    onUpdateDashboard()
    onUpdateJournals()
    selectJournalModal.close()
  }

  return (
    <TradesTableSectionContext.Provider value={providerValue}>
      {children}
      <Modal controller={selectJournalModal}>
        <SelectJournalDialog
          trade={selectedTrade}
          onSelectJournal={(journal) => moveSelectedTrade(journal)}
          onClose={() => selectJournalModal.close()}
          isLoading={isLoading}
        />
      </Modal>
      <Modal controller={addEditNoteModal}>
        <AddEditNoteModal onClose={() => addEditNoteModal.close()} />
      </Modal>
      <Modal controller={tradeInfoModal}>
        <TradeInfoModal onClose={() => tradeInfoModal.close()} />
      </Modal>
      <Modal controller={confirmDeleteModal}>
        <ModalLayout>
          <ModalLayout.CloseButton onClick={() => confirmDeleteModal.close()} />
          <ModalLayout.Heading>Delete trade</ModalLayout.Heading>
          <ModalLayout.Description>
            Once removed, this action cannot be undone. The user will lose access to the trade and
            any associated data.
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmDeleteModal.close()}>Cancel</Button>
            <Button color="error" isLoading={isLoading} onClick={() => deleteSelectedTrade()}>
              Delete
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      <TableConfigModal controller={tableConfigModal} />
    </TradesTableSectionContext.Provider>
  )
}
