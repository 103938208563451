import { useContext } from 'react'
import { NotificationsContext } from './NotificationsContext'
import { NotificationCard } from './NotificationCard'

import './Notifications.scss'

export function NotificationsList() {
  const { notifications, removeNotification } = useContext(NotificationsContext)

  return (
    <div className="NotificationsList">
      {notifications.map((item) => (
        <NotificationCard item={item} key={item.id} onClose={(id) => removeNotification(id)} />
      ))}
    </div>
  )
}
