import { useEffect, useRef } from 'react'

/** Scroll to selected time in time picker when date picker is opened */
export function useTimePickerAutoscroll(open: boolean) {
  const timePickerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!open) return
    if (!timePickerRef.current) return
    const button = timePickerRef.current.querySelector('button.selected')
    if (!button) return
    button.scrollIntoView({ block: 'center' })
  }, [open])

  return { timePickerRef }
}
