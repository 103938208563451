import { JournalDetailedInfo, JournalInfo } from 'core/types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { EditJournalForm, EditJournalFormData } from './EditJournalForm'
import { ModalController } from 'shared/Modal'
import { AxiosError } from 'axios'

type EditJournalDialogProps = {
  controller: ModalController<boolean>
  isLoading: boolean
  journal: JournalInfo | JournalDetailedInfo | null
  onEdit: (journalId: string, journalType: string) => Promise<unknown>
}
export function EditJournalModal({
  controller,
  journal,
  onEdit,
  isLoading,
}: Readonly<EditJournalDialogProps>) {
  const [occupiedName, setOccupiedName] = useState<string | null>(null)
  const useFormReturn = useForm<EditJournalFormData>({
    values: { journalName: journal?.journalName ?? '', journalType: journal?.journalType ?? '' },
  })
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid },
  } = useFormReturn

  const onSubmit = handleSubmit(async (data) => {
    if (!isValid) return
    if (!journal) return
    if (data.journalName === journal.journalName && data.journalType === journal.journalType) {
      return controller.close()
    }
    try {
      await onEdit(data.journalName, data.journalType)
      setValue('journalName', '')
      setValue('journalType', '')
      controller.close()
    } catch (err) {
      if (err instanceof AxiosError && err.status === 400) {
        setOccupiedName(data.journalName)
        setTimeout(() => trigger())
      }
    }
  })

  return (
    <EditJournalForm
      loading={isLoading}
      occupiedName={occupiedName}
      useFormReturn={useFormReturn}
      onCancel={() => controller.close()}
      onSubmit={onSubmit}
      actionText="Edit"
      disableTypeDropdown={(journal as JournalDetailedInfo)?.typeEditable === false}
    />
  )
}
