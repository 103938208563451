import { useCallback, useEffect, useState } from 'react'

export type LocalSortingSchema<T> = { [key in keyof Partial<T>]: (a: T[key], b: T[key]) => number }

const DEFAULT_SORTING = (a: unknown, b: unknown): number => {
  if (a == null) return -1
  if (b == null) return 1
  if (a == null && b == null) return 0
  if (typeof a === 'number' && typeof b === 'number') return a - b
  if (typeof a === 'string' && typeof b === 'string') return a.localeCompare(b)
  if (typeof a?.toString === 'function' && typeof b?.toString === 'function') {
    return a.toString().localeCompare(b.toString())
  }
  return JSON.stringify(a).localeCompare(JSON.stringify(b))
}

export function useLocalSorting<T extends object>(
  initialKey: keyof T,
  schema: LocalSortingSchema<T>
) {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [sortingKey, setSortingKey] = useState<keyof T>(initialKey)
  const [items, setItems] = useState<T[]>([])
  const [sortedItems, setSortedItems] = useState<T[]>([])

  const sortItemsCb = useCallback(() => {
    const sortedItems = [...items].sort((a, b) => {
      const schemaEntry = schema[sortingKey]
      const compareResult = (schemaEntry ?? DEFAULT_SORTING)(a[sortingKey], b[sortingKey])
      return direction === 'asc' ? compareResult : -compareResult
    })
    setSortedItems(sortedItems)
  }, [sortingKey, direction, schema, items])

  useEffect(() => sortItemsCb(), [sortItemsCb, items])

  const sortItems = (key: keyof T) => {
    if (key === sortingKey) {
      setDirection(direction === 'asc' ? 'desc' : 'asc')
    } else {
      setSortingKey(key)
      setDirection('asc')
    }
  }

  return {
    sortItems,
    sortingKey,
    direction,
    setItems,
    sortedItems,
  }
}
