import { PnlItem, TradeItem, TradesResponse, TradingStatistics } from 'core/types'
import { API_URL } from 'core/constants'
import useSWR from 'swr'
import { useMemo } from 'react'
import { apiClientFetcher } from './apiClientFetcher'
import { FilterState } from 'pages/DashboardPage/FilterContext'
import { makeDateConsumableForApi } from 'core/utils'
import apiClient from './apiClient'

/* TO DO: move to somewhere else */
export type PagingParams = {
  page: number
  size: number
}
export type SortingParams<T extends object> = {
  sort: keyof T
  order: 'asc' | 'desc'
}

export type DashboardTradesTableSearchParams = FilterState & SortingParams<TradeItem> & PagingParams

const calcSearchParams = (
  filters: FilterState,
  pagingSortingInfo?: PagingParams & SortingParams<TradeItem>
): string => {
  const searchParams = new URLSearchParams()
  searchParams.set('fromTime', makeDateConsumableForApi(filters.dateFrom))
  searchParams.set('toTime', makeDateConsumableForApi(filters.dateTo))
  if (filters.symbols.length > 0) searchParams.set('instrumentAliases', filters.symbols.join(','))
  if (filters.journal) searchParams.set('journalIds', filters.journal)

  if (pagingSortingInfo) {
    searchParams.set('page', pagingSortingInfo.page.toString())
    searchParams.set('size', pagingSortingInfo.size.toString())
    searchParams.set('sort', [pagingSortingInfo.sort, pagingSortingInfo.order].join(','))
  }
  return searchParams.toString()
}
const useCalculatedParams = (
  filters: FilterState,
  pagingSortingInfo?: PagingParams & SortingParams<TradeItem>
) => useMemo(() => calcSearchParams(filters, pagingSortingInfo), [filters, pagingSortingInfo])

export const usePnl = (filters: FilterState) => {
  const searchParams = useCalculatedParams(filters)
  const url = API_URL + '/api/dashboard/pnl?' + searchParams
  return useSWR(url, apiClientFetcher<{ result: { dataPoints: PnlItem[] } }>)
}

export const useStatistics = (filters: FilterState) => {
  const searchParams = useCalculatedParams(filters)
  const url = API_URL + '/api/dashboard/statistics?' + searchParams
  return useSWR(url, apiClientFetcher<{ result: TradingStatistics }>)
}

export const useTrades = (
  filters: FilterState,
  pagingSortingInfo: PagingParams & SortingParams<TradeItem>
) => {
  const searchParams = useCalculatedParams(filters, pagingSortingInfo)
  const baseUrl = API_URL + '/api/dashboard/trades'
  const url = baseUrl + '?' + searchParams

  //   const result = useSWR(baseUrl, async () => (await apiClient.get<TradesResponse>(url)).data)
  //   useEffect(() => {
  //     result.mutate()
  //   }, [result.data, pagingSortingInfo, filters])
  //   return result

  return useSWR(url, async () => (await apiClient.get<TradesResponse>(url)).data)
}

export const addNote = async (noteText: string, tradeId: string) => {
  return apiClient.post(API_URL + `/api/dashboard/trades/${tradeId}/note`, { noteText })
}
export const editNote = async (noteText: string, tradeNoteId: string) => {
  return apiClient.put(API_URL + `/api/dashboard/trades/note/${tradeNoteId}`, { noteText })
}

export const getExportTradesToCsv = async (filters: FilterState) => {
  const searchParams = calcSearchParams(filters)
  const url = API_URL + '/api/dashboard/trades/csv?' + searchParams
  return apiClient.get(url, { responseType: 'blob' })
}

export const getExportTradesToExcel = async (filters: FilterState) => {
  const searchParams = calcSearchParams(filters)
  const url = API_URL + '/api/dashboard/trades/excel?' + searchParams
  return apiClient.get(url, { responseType: 'blob' })
}
