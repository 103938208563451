import { createContext, useContext } from 'react'
import { JournalsApiContextData } from './models'

export const JournalsApiContext = createContext<JournalsApiContextData>({
  updateJournals: () => {
    throw new Error('Not implemented')
  },
  openAddDialog: () => {
    throw new Error('Not implemented')
  },
  openEditDialog: () => {
    throw new Error('Not implemented')
  },
  openDeleteDialog: () => {
    throw new Error('Not implemented')
  },
  openShareDialog: () => {
    throw new Error('Not implemented')
  },
  openRevokeAccessForMeModal: () => {
    throw new Error('Not implemented')
  },
})
export const useJournalsApi = () => useContext(JournalsApiContext)
