import { AllHTMLAttributes } from 'react'

type TableHeaderProps = { children: React.ReactNode } & AllHTMLAttributes<HTMLTableSectionElement>
export function TableHeader({ children, ...rest }: Readonly<TableHeaderProps>) {
  return (
    <thead className="TableHeader" {...rest}>
      <tr>{children}</tr>
    </thead>
  )
}
