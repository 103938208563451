import './Tooltip.scss'
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { AllHTMLAttributes, useState } from 'react'

export type TooltipProps = AllHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  tooltipContent: React.ReactNode
  delay?: number
}
export function Tooltip({
  children,
  tooltipContent: content,
  delay,
  ...props
}: Readonly<TooltipProps>) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift({ padding: 8 }),
    ],
  })

  const hover = useHover(context, { move: false, delay })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  return (
    <div className="Tooltip" {...props}>
      <div className="Tooltip__trigger" ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && content && (
        <FloatingPortal>
          <div
            className="Tooltip__content"
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}
