import imgCaretDown from 'assets/icons/caret-down.svg'
import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'
import { Button } from 'shared/Button'

type DropdownTriggerProps = {
  opened: boolean
  placeholder: string
  value?: ReactNode
  onBlur?: () => void
  tooltipContent?: React.ReactNode
}
export const DropdownTrigger = forwardRef<HTMLButtonElement, DropdownTriggerProps>(
  ({ opened, placeholder, value, onBlur, tooltipContent }, ref) => {
    return (
      <Button
        ref={ref}
        type="button"
        appearance="input-trigger"
        className="DropdownTrigger text-normal-regular"
        fontType="text"
        tooltipContent={tooltipContent}
        onBlur={() => onBlur && onBlur()}
      >
        {value !== undefined ? (
          <div className="DropdownTrigger__content value">{value}</div>
        ) : (
          <div className="DropdownTrigger__content placeholder">{placeholder}</div>
        )}

        <img
          src={imgCaretDown}
          alt="Caret"
          className={classNames(
            'DropdownTrigger__arrow',
            opened && 'DropdownTrigger__arrow--flipped'
          )}
        />
      </Button>
    )
  }
)
