import { PageLayoutCustomFooter } from './PageLayoutCustomFooter'

export function PageLayoutFooter() {
  return (
    <PageLayoutCustomFooter className="PageLayoutFooterDefault">
      Have something to say? Email us at{' '}
      <a href="mailto:trading.journal@bookmap.com" target="_blank" rel="noreferrer">
        trading.journal@bookmap.com
      </a>
    </PageLayoutCustomFooter>
  )
}
