import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { calcRequiredOffsetDays, getInitialTime } from './utils'
import { add, set } from 'date-fns'

/** Date -> 12:34 */
export function useCompactTimeFormatter() {
  const { getDateTimeFormatter } = useGlobalTimezone()
  return useMemo(
    () => getDateTimeFormatter({ hour: 'numeric', minute: 'numeric' }),
    [getDateTimeFormatter]
  )
}

export function useCombinedDate() {
  const [baseDate, setBaseDate] = useState<Date>(getInitialTime())
  const [baseTime, setBaseTime] = useState<Date>(getInitialTime())
  const [combinedDate, setCombinedDate] = useState<Date>(getInitialTime())
  const { timeZone } = useGlobalTimezone()

  /* on value changes */
  const updateCombinedDate = useCallback(
    (value: Date) => {
      const dateZeroTime = set(value, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
      const trueBaseDate = add(dateZeroTime, {
        days: calcRequiredOffsetDays(value, timeZone.offsetMsRelative) * 1,
      })
      setBaseDate(trueBaseDate)
      setBaseTime(value)
    },
    [timeZone.offsetMsRelative]
  )

  const today = useCallback(
    () =>
      updateCombinedDate(
        new Date(
          set(new Date(), {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }).getTime() - timeZone.offsetMsRelative
        )
      ),
    [updateCombinedDate, timeZone.offsetMsRelative]
  )

  /* update combinedDate */
  useEffect(() => {
    const combinedDate = set(baseDate, {
      hours: baseTime.getHours(),
      minutes: baseTime.getMinutes(),
    })
    const adjustedCombinedDate = add(combinedDate, {
      days: calcRequiredOffsetDays(combinedDate, timeZone.offsetMsRelative) * -1,
    })

    setCombinedDate(adjustedCombinedDate)
  }, [baseDate, baseTime, timeZone.offsetMsRelative])

  return {
    today,
    baseDate,
    setBaseDate,
    baseTime,
    setBaseTime,
    combinedDate,
    setCombinedDate: updateCombinedDate,
  }
}
