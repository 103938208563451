import { LS_TRADES_TABLE_COLUMNS } from 'core/constants'
import { TRADES_TABLE_KEYS_DEFAULT } from './constants'
import { AllowedTradeKeys } from './models'

export function getInitialTradesTableKeys(): AllowedTradeKeys[] {
  const result = localStorage.getItem(LS_TRADES_TABLE_COLUMNS)
  if (!result) return TRADES_TABLE_KEYS_DEFAULT
  try {
    return JSON.parse(result) as AllowedTradeKeys[]
  } catch (e) {
    return TRADES_TABLE_KEYS_DEFAULT
  }
}
