import './DashboardPage.scss'
import { DashboardApiContextProvider } from './DashboardApiContext'
import { FilterContextProvider } from 'pages/DashboardPage/FilterContext'
import PageLayout from 'shared/PageLayout/PageLayout'
import Dashboard from './Dashboard'
import { useDashboardPageSearchParamsResolver } from './useDashboardPageSearchParamsResolver'

export function DashboardPage() {
  return (
    <FilterContextProvider>
      <DashboardApiContextProvider>
        <DashboardPageWithContexts />
      </DashboardApiContextProvider>
    </FilterContextProvider>
  )
}

function DashboardPageWithContexts() {
  useDashboardPageSearchParamsResolver()

  return (
    <PageLayout>
      <PageLayout.Header />
      <PageLayout.Content>
        <Dashboard />
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
