import { FC, useMemo } from 'react'
import './Chart.scss'
import { Chart } from './Chart'
import { useGlobalTimezone, useGlobalTimezoneClock } from 'core/contexts/GlobalTimezoneContext'
import { Tooltip } from 'shared/Tooltip'
import { reduceArrayTo } from 'core/utils'
import { useDashboardApi } from 'pages/DashboardPage/DashboardApiContext'

export type ChartItem = { time: number; value: number }

type Props = { label: string }
/** Chart wrapper with header & some minor handling of empty data */
export const ChartSection: FC<Props> = ({ label }) => {
  const {
    pnl: { data },
  } = useDashboardApi()

  const transformedData = useMemo(() => {
    if (!data) return []
    const reduced = reduceArrayTo(data.result.dataPoints, 1000)
    return reduced.map((item) => ({
      time: new Date(item.time).getTime(),
      value: item.value,
    }))
  }, [data])

  const timeFormatted = useGlobalTimezoneClock()
  const { timeZone } = useGlobalTimezone()

  return (
    <div className="Chart">
      <div className="Chart__label">
        <div>{label}</div>

        <Tooltip
          tooltipContent='To change the timezone, go to Journal manager page by clicking on "Manage"'
          delay={0}
        >
          <div>{timeFormatted + ' (UTC' + timeZone.timezoneOffsetName + ')'}</div>
        </Tooltip>
      </div>
      {data !== null ? (
        <div className="Chart__content">
          {data?.result.dataPoints.length === 0 ? (
            <div className="empty-state">No data</div>
          ) : (
            <Chart items={transformedData} />
          )}
        </div>
      ) : (
        <div className="Chart__content_placeholder"></div>
      )}
    </div>
  )
}
