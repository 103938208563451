type MultiselectDropdownItemProps = {
  label: string
  value: boolean
  id: string
  onChange: (value: boolean) => void
}
export function MultiselectDropdownItem({
  label,
  value,
  onChange,
  id,
}: Readonly<MultiselectDropdownItemProps>) {
  return (
    <div className="MultiselectDropdownItem">
      <input type="checkbox" id={id} checked={value} onChange={() => onChange(!value)} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
