import './StatisticsSection.scss'
import { SimpleStatisticsInfo } from './SimpleStatisticsInfo/SimpleStatisticsInfo'

import iconUlrFinalUp from 'assets/icons/final_up.svg'
import iconUlrFinalDown from 'assets/icons/final_down.svg'
import iconUlrCornerUp from 'assets/icons/corner_arrow_up.svg'
import iconUlrCornerDown from 'assets/icons/corner_arrow_down.svg'
import { BarInfoSection } from './BarInfoSection/BarInfoSection'
import { formatMoney } from 'core/utils'
import TextMoney from 'shared/TextMoney/TextMoney'
import { useDashboardApi } from '../../../DashboardApiContext'
import Card from 'shared/Card'
import { useMemo } from 'react'

export function StatisticsSection() {
  const {
    statistics: { data, isLoading },
  } = useDashboardApi()

  const makeCompact = useMemo(() => {
    return (data?.result.totalLossesSize ?? 0) < -10e3 || (data?.result.totalWinsSize ?? 0) > 10e3
  }, [data])

  if (isLoading)
    return (
      <div className="StatisticsSection">
        <div className="StatisticsSection__card-skeleton"></div>
        <div className="StatisticsSection__card-skeleton"></div>
        <div className="StatisticsSection__card-skeleton"></div>
        <div className="StatisticsSection__card-skeleton"></div>
      </div>
    )

  const getWinLossRatio = () => {
    const win = data?.result.totalWinsSize ?? 0
    const loss = Math.abs(data?.result.totalLossesSize ?? 0)

    return Math.round((win / (win + loss)) * 100)
  }

  return (
    <div className="StatisticsSection">
      <Card>
        <BarInfoSection
          label="Total PnL"
          className={makeCompact ? 'compact' : ''}
          tooltipText="Overall profit or loss"
          labelPositive="Total win"
          labelNegative="Total loss"
          valuePositive={<TextMoney value={data?.result.totalWinsSize ?? 0} />}
          valueNegative={<TextMoney value={data?.result.totalLossesSize ?? 0} />}
          ratio={getWinLossRatio()}
        >
          <TextMoney value={data?.result.totalPnl ?? 0} colored />
        </BarInfoSection>
      </Card>

      <Card>
        <SimpleStatisticsInfo
          color="green"
          imgUrl={iconUlrFinalUp}
          title="Biggest Win Trade"
          tooltipText="Largest profitable trade"
          value={formatMoney(data?.result.biggestWin ?? 0)}
        />
        <div className="divider"></div>
        <SimpleStatisticsInfo
          color="red"
          imgUrl={iconUlrFinalDown}
          title="Biggest Loss Trade"
          tooltipText="Largest losing trade"
          value={formatMoney(data?.result.biggestLoss ?? 0)}
        />
      </Card>

      <Card>
        <BarInfoSection
          label="Profit ratio"
          tooltipText="Win rate percentage"
          labelPositive="Profit trades"
          labelNegative="Loss trades"
          valuePositive={data?.result.wins ?? 0}
          valueNegative={data?.result.losses ?? 0}
          ratio={data?.result.winLossRatio ?? 0}
        >
          {data?.result.winLossRatio === 'NaN'
            ? 'N/A'
            : Math.round(data?.result.winLossRatio ?? 0) + '%'}
        </BarInfoSection>
      </Card>

      <Card>
        <SimpleStatisticsInfo
          color="green"
          imgUrl={iconUlrCornerUp}
          title="Average Win Trade"
          tooltipText="Average profit per winning trade"
          value={formatMoney(data?.result.averageWinSize ?? 0)}
        />
        <div className="divider"></div>
        <SimpleStatisticsInfo
          color="red"
          imgUrl={iconUlrCornerDown}
          title="Average Loss Trade"
          tooltipText="Average loss per losing trade"
          value={formatMoney(data?.result.averageLossSize ?? 0)}
        />
      </Card>
    </div>
  )
}
