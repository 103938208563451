import './TradesTableSection.scss'
import { Pagination } from './components/Pagination/Pagination'
import { Card } from 'shared/Card/Card'
import { useDashboardApi } from '../../../DashboardApiContext'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import {
  TradesTableSectionContext,
  TradesTableSectionContextProvider,
} from './TradesTableSectionContext'
import { TradesTable } from './components/TradesTable/TradesTable'
import { useCallback, useContext, useMemo, useState } from 'react'
import Menu from 'shared/Menu'
import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { getExportTradesToCsv, getExportTradesToExcel } from 'core/api/dashboard'
import { useSelectedJournal } from '../Filters/useSelectedJournal'

function useTradesExport() {
  const [loading, setLoading] = useState(false)
  const { filters } = useContext(FilterContext)
  const selectedJournal = useSelectedJournal()

  const fileName = useMemo(() => {
    if (selectedJournal === null) return `All my trades`
    return `${selectedJournal.journalName} trades`
  }, [selectedJournal])

  const exportAsCsv = useCallback(async () => {
    setLoading(true)
    try {
      const response: Blob = (await getExportTradesToCsv(filters)).data
      const url = URL.createObjectURL(response)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName + '.csv'
      a.click()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [fileName, filters])
  const exportAsXll = useCallback(async () => {
    setLoading(true)
    try {
      const response: Blob = (await getExportTradesToExcel(filters)).data
      const url = URL.createObjectURL(response)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName + '.xlsx'
      a.click()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [fileName, filters])

  return { exportAsCsv, exportAsXll, loading }
}

export function TradesTableSectionRaw() {
  const { trades, setPaginationPage, paginationPage } = useDashboardApi()
  const { openTableConfigModal } = useContext(TradesTableSectionContext)
  if (paginationPage !== 0 && trades.data && trades.data.result.length === 0) setPaginationPage(0)
  const { exportAsCsv, exportAsXll, loading } = useTradesExport()

  if (!trades.data) return <Card className="TradesTableSection loading"></Card>

  return (
    <Card className="TradesTableSection">
      <div className="header text-normal-regular">
        Trade history
        <div className="buttons">
          <Menu
            trigger={
              <Button size="small" isLoading={loading}>
                <Icon name="Download" width={16} height={16} />
                Export
              </Button>
            }
          >
            <Menu.Item onClick={() => exportAsCsv()}>as .csv</Menu.Item>
            <Menu.Item onClick={() => exportAsXll()}>as .xlsx</Menu.Item>
          </Menu>

          <Button size="small" square onClick={() => openTableConfigModal()}>
            <Icon name="Settings" />
          </Button>
        </div>
      </div>
      <TradesTable data={trades.data} isLoading={trades.isLoading} />
      <Pagination
        pagesTotal={trades.data?.pageInfo.totalPages ?? 0}
        currentPage={paginationPage}
        requestPage={(v) => setPaginationPage(v)}
      />
    </Card>
  )
}

function TradesTableSection() {
  return (
    <TradesTableSectionContextProvider>
      <TradesTableSectionRaw />
    </TradesTableSectionContextProvider>
  )
}

export { TradesTableSection }
